import React, { FC, ReactNode } from 'react';

import moment, { Moment } from 'moment';
import { Calendar, Popover } from 'antd';

import Clock from '../../apps/clock/Clock';
import Date from '../../apps/date/Date';
import Icon from '../icon/Icon';
import { HeaderRender } from 'antd/lib/calendar/generateCalendar';

const DateTime: FC = () => {
  const calendarTitle = (
    <div className="footer-datetime__xl">
      <Clock showSeconds />
      <Date />
    </div>
  );

  const dateCellRender = (date: Moment): ReactNode => {
    const isToday = moment().isSame(date, 'day');

    const style = {
      padding: '0.5rem 1rem',
      background: isToday ? '#0A3B76' : 'transparent',
    };

    return <span style={style}>{date.format('D')}</span>;
  };

  const headerRender: HeaderRender<Moment> = ({ value, onChange }) => {
    const handleChange = (type: 'increment' | 'decrement') => {
      const newValue = value.clone();
      if (type === 'increment') {
        newValue.month(newValue.month() + 1);
      } else {
        newValue.month(newValue.month() - 1);
      }

      onChange(newValue);
    };
    return (
      <div className="footer-calendar-header">
        <span>{moment(value).format('MMMM YYYY')}</span>
        <div className="footer-calendar-header__actions">
          <Icon type="UpOutlined" onClick={() => handleChange('decrement')} />
          <Icon type="DownOutlined" onClick={() => handleChange('increment')} />
        </div>
      </div>
    );
  };

  return (
    <Popover
      content={
        <Calendar
          fullscreen={false}
          className="footer-calendar"
          dateFullCellRender={dateCellRender}
          headerRender={headerRender}
        />
      }
      title={calendarTitle}
      trigger="click"
      align={{ offset: [0, 16] }}
      overlayStyle={{ width: '36rem', zIndex: 0 }}
    >
      <div className="footer-datetime">
        <Clock />
        <Date short />
      </div>
    </Popover>
  );
};

export default DateTime;
