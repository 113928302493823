import React, { FC } from 'react';

import Icon from '../icon/Icon';
import moment from 'moment';

interface Skill {
  name: string;
  icon: string;
  since: string;
  description: string;
}

const SKILLS: Array<Skill> = [
  {
    name: 'HTML',
    icon: 'custom_html',
    since: '2015',
    description:
      'Understand the structure and ways to optimise the code for speed, readability and SEO. Aware of limits and how to work around them.',
  },
  {
    name: 'CSS',
    icon: 'custom_css',
    since: '2015',
    description:
      "Familiar with Bootstrap, Foundation and Material UI. I'm also comfortable writing in pure CSS, including flex-box and grid.",
  },
  {
    name: 'SCSS',
    icon: 'custom_scss',
    since: '2017',
    description:
      "I support Sass' modular architecture and DRY principles. Able to use mixins, variables and functions to their full potential.",
  },
  {
    name: 'JavaScript',
    icon: 'custom_javascript',
    since: '2016',
    description: 'I believe in strong vanilla JavaScript foundation before any frameworks/libraries.',
  },
  {
    name: 'ReactJS',
    icon: 'custom_react',
    since: '2017',
    description: 'Building anything from simple static websites to large scale SaaS solutions.',
  },
  {
    name: 'NodeJS',
    icon: 'custom_nodejs',
    since: '2019',
    description: 'Comfortable building semi-complex REST APIs using latest industry practices.',
  },
  {
    name: 'GIT',
    icon: 'custom_git',
    since: '2015',
    description:
      'Have practical experience with most features including tags, branches, merging, code-review, git-flow, pipelines, web-hooks...etc.',
  },
];

const Skills: FC = () => {
  return (
    <div className="widget-skills">
      {SKILLS.map((item: Skill) => (
        <div className="skills-item" key={`skill-item-${item.name}`}>
          <div className="skills-header">
            <div>
              <Icon type={item.icon} />
              <span>{item.name}</span>
            </div>
            <div>
              <span>{moment().diff(item.since, 'year')} years</span>
            </div>
          </div>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Skills;
