import React, { FC, MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Popover } from 'antd';

import useFullscreen from './useFullscreen';
import Icon from '../components/icon/Icon';
import ScreenWarning from '../components/screenWarning/ScreenWarning';
import { RootStore } from '../redux/store';

interface ScreenControlProps {
  children: ReactNode;
}

interface Constrains {
  width: number;
  height: number;
}

const CONSTRAINS: Constrains = {
  width: 1000,
  height: 650,
};

const ScreenControl: FC<ScreenControlProps> = ({ children }) => {
  const [isDesktop, setIsDesktop] = useState<boolean | null>(null);
  const [resolution, setResolution] = useState<Constrains>({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [visible, setVisible] = useState<boolean>(true);
  const fullscreenElement = useRef<HTMLElement>(document.body);
  const { isFullscreen, setFullscreen } = useFullscreen(fullscreenElement);
  const { powerOn, isLoading } = useSelector((state: RootStore) => state.appControl);

  const exitFullscreen: MouseEventHandler<HTMLDivElement> = () => document.exitFullscreen();

  const handleResize = () => {
    const currentHeight: number = window.innerHeight;
    const currentWidth: number = window.innerWidth;
    const baseFS = 62.5; // Base font size in %
    const maxFS = 70; // Maximum font size in %
    const baseHeight = 1080;
    const baseWidth = 1920;

    setResolution({
      height: currentHeight,
      width: currentWidth,
    });

    const notAllowed = currentWidth < CONSTRAINS.width || currentHeight < CONSTRAINS.height;
    if (notAllowed) {
      setIsDesktop(false);
    }

    if (currentHeight !== baseHeight || currentWidth !== baseWidth) {
      let scalePercentage: number;
      let newFS: number;

      if (notAllowed) {
        scalePercentage = Number((currentHeight / baseHeight).toFixed(2));
        newFS = baseFS * scalePercentage;
      } else {
        scalePercentage = Number(((currentHeight * currentWidth) / (baseHeight * baseWidth)).toFixed(2));
        newFS = Math.min(maxFS, baseFS * scalePercentage);
      }

      document.documentElement.style.fontSize = `${newFS}%`; // App responsiveness is in "rem", so it depends on the document font size
    }
  };

  useEffect(() => {
    if (powerOn && !isLoading) {
      // Hide popover after 3 seconds
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [powerOn, isLoading]);

  useEffect(() => {
    handleResize();
    setIsDesktop(resolution.height >= CONSTRAINS.height && resolution.width >= CONSTRAINS.width);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isDesktop === null ? null : isDesktop ? (
        <div>{children}</div>
      ) : (
        <ScreenWarning height={resolution.height} width={resolution.width} enter={() => setIsDesktop(true)} />
      )}
      {powerOn && !isLoading && (
        <Popover placement="left" title="" content="Try in fullscreen for best experience" visible={visible}>
          <Icon
            className="fullscreen-trigger"
            type={isFullscreen ? 'FullscreenExitOutlined' : 'FullscreenOutlined'}
            onClick={isFullscreen ? exitFullscreen : setFullscreen}
          />
        </Popover>
      )}
    </>
  );
};

export default ScreenControl;
