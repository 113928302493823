import { compact, debounce } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ttt_makeMove, ttt_setAITurn, ttt_setDraw, ttt_setOWin, ttt_setXWin } from '../../redux/ticTacToeSlice';
import { findBestMove, gameBoard } from './AI';
import { useAudio } from '../../util/useAudio';
import clickSoundRaw from './sounds/click.mp3';
import loseSoundRaw from './sounds/lose.mp3';
import winSoundRaw from './sounds/win.mp3';
import Timer from './Timer';
import { RootStore } from '../../redux/store';
import { BoardType, Piece } from './types';
import { Fn } from '../../util/types';

interface BoardProps {
  onGameEnd: Fn;
}

const Board: FC<BoardProps> = ({ onGameEnd }) => {
  const { audio: clickSound } = useAudio(clickSoundRaw);
  const { audio: loseSound } = useAudio(loseSoundRaw);
  const { audio: winSound } = useAudio(winSoundRaw);
  const { isGameEnd, isTurnX, isPvP, squares, isTurnAI, player } = useSelector((state: RootStore) => state.ticTacToe);
  const dispatch = useDispatch();

  const calculateWinner = (squares: BoardType) => {
    const lines: Array<Array<number>> = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    let winner: [Piece | boolean, string | null] = [
      squares.filter((square: any) => square === null).length === 0,
      null,
    ];

    lines.forEach(line => {
      const [a, b, c] = line;
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        winner = [squares[a] as Piece, `${a}-${c}`];
      }
    });

    return winner;
  };

  const setWinner = (winner: Piece) => {
    if (winner === Piece.x) {
      player === Piece.x ? winSound.play() : loseSound.play();
      dispatch(ttt_setXWin());
    } else if (winner === Piece.o) {
      player === Piece.o ? winSound.play() : loseSound.play();
      dispatch(ttt_setOWin());
    } else {
      dispatch(ttt_setDraw());
    }
  };

  const handleClick = (i: number, ai = false) => {
    if (isGameEnd || squares[i] !== null || (!ai && isTurnAI)) return;

    clickSound.play();
    const _squares = [...squares];
    _squares[i] = isTurnX ? Piece.x : Piece.o;
    dispatch(ttt_makeMove(_squares));

    const [winner] = calculateWinner(_squares);
    if (winner) {
      setWinner(winner as Piece);
      onGameEnd();
    } else if (!ai) {
      dispatch(ttt_setAITurn(true));
    }
  };

  let winningLineClass = '';

  if (isGameEnd) {
    const [, dir] = calculateWinner(squares);

    if (dir) {
      winningLineClass = ' winning-line-wrap-' + dir;
    }
  }

  const debouncedClick = debounce(handleClick, 1000, {
    leading: true,
    trailing: false,
  });

  const randomPlay = () => {
    const firstAvailableMove = squares.findIndex((item: any) => !item);
    handleClick(firstAvailableMove);
  };

  useEffect(() => {
    if (!isGameEnd && !isPvP && isTurnAI) {
      // pretend that AI is "thinking"
      const playedMoves = compact(squares);
      const move = playedMoves.length
        ? findBestMove(gameBoard(squares, isTurnX ? Piece.x : Piece.o))
        : Math.floor(Math.random() * 8);
      handleClick(move, true);
      dispatch(ttt_setAITurn(false));
    }
  });

  return (
    <div style={{ position: 'relative', width: '32.5rem' }}>
      {!isTurnAI && <Timer onTimeOut={randomPlay} />}
      <div className={'winning-line-wrap' + winningLineClass}>
        <div className="winning-line" />
      </div>
      <div className="ttt-board">
        {Array(9)
          .fill(null)
          .map((_, i) => (
            <div
              key={`ttt-btn-${i}`}
              className="ttt-btn-wrapper"
              onClick={() => debouncedClick(i)}
              style={{
                pointerEvents: isTurnAI || squares[i] !== null ? 'none' : 'initial',
              }}
            >
              <button className="ttt-btn" disabled={isTurnAI || squares[i] !== null}>
                {squares[i]?.toUpperCase()}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Board;
