import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';

import { ac_loaded } from './redux/appControlSlice';
import useApps, { AppProps } from './apps/appCenter';

import Icon from './components/icon/Icon';
import TurnedOff from './components/turnedOff/TurnedOff';
import Desktop from './components/desktop/Desktop';
import Dialog from './components/dialog/Dialog';
import AppLoading from './components/appLoading/AppLoading';
import AppInfo from './components/appInfo/AppInfo';
import Footer from './components/footer/Footer';
import { EVENT, initAnalytics, mpEvent } from './util/Analytics';
import { RootStore } from './redux/store';

const App: FC = () => {
  const [runningApps, setRunningApps] = useState<Array<AppProps>>([]);
  const { running, closeApp, apps, isActive } = useApps();
  const { isLoading, powerOn } = useSelector((state: RootStore) => state.appControl);

  const dispatch = useDispatch();

  useEffect(() => {
    initAnalytics();
  }, []);

  useEffect(() => {
    const runningApps = running.map((item: string) => {
      const [appInfo, appID] = item.split('_');
      if (appInfo === 'appInfo') {
        return {
          appID: item,
          appName: 'About Application',
          width: 600,
          component: () => <AppInfo appID={appID} />,
          maximizable: false,
          toolbar: null,
          icon: 'InfoCircleFilled',
        };
      }

      return apps.find(app => app.appID === item);
    });
    // @ts-ignore
    setRunningApps(runningApps);
  }, [apps, running]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        dispatch(ac_loaded());
      }, 5000);
    }
  }, [isLoading, dispatch]);

  useLayoutEffect(() => {
    const seen = sessionStorage.getItem('seenOptionDialog');
    if (!isLoading && powerOn && !seen) {
      sessionStorage.setItem('seenOptionDialog', 'yes');
      setTimeout(() => {
        Modal.confirm({
          okText: 'Leave',
          onOk: () => {
            mpEvent(EVENT.outbound_link, 'simple_page');
            window.location.href = 'https://simple.nbojanovic.dev/';
          },
          cancelText: "I'll stay",
          title: 'Hey there!',
          content: 'Do you wish to look around or go to a simple portfolio page?',
        });
      }, 1000);
    }
  }, [isLoading, powerOn]);

  if (!powerOn) return <TurnedOff />;
  if (isLoading && powerOn) return <AppLoading />;
  return (
    <div className="viewport">
      <Desktop />
      <Footer />
      {runningApps.map(runningApp => (
        <Dialog
          key={runningApp.appID}
          isVisible={!!runningApp}
          onCancel={() => closeApp(runningApp.appID)}
          width={runningApp.width}
          component={runningApp.component}
          title={
            <span className="dialog-title">
              <Icon type={runningApp?.icon} className="dialog-icon" />
              <span>{runningApp.appName}</span>
            </span>
          }
          maximizable={runningApp.maximizable}
          toolbar={runningApp.toolbar}
          appID={runningApp.appID}
          zIndex={isActive(runningApp.appID) ? 1001 : 1000}
          wrapClassName={`appID-${runningApp.appID}`}
        />
      ))}
      <div className="author">by Nikola Bojanović</div>
    </div>
  );
};

export default App;
