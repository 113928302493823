import React, { FC } from 'react';
import Icon from '../icon/Icon';
import { Button } from 'antd';

interface ScreenWarningProps {
  height: number;
  width: number;
  enter: () => void;
}

const ScreenWarning: FC<ScreenWarningProps> = ({ height, width, enter }) => {
  const pixelToRelativeSize = (pix: number) => pix / 5;
  const allowEntry: boolean = height >= 650 && width >= 1000;

  const leave = () => {
    window.open('https://m.nbojanovic.dev/', '_self');
  };

  return (
    <div className="screen-warning">
      {allowEntry ? (
        <>
          <Icon type="CheckCircleFilled" className="screen-warning-icon--success" />
          <h3>All good. You can enter now.</h3>
          <Button onClick={enter}>Enter</Button>
        </>
      ) : (
        <>
          <Icon type="WarningFilled" className="screen-warning-icon" />
          <h3>
            Your screen size is not supported. If you're using desktop browser, try maximizing it or go into full screen
            (<code>F11</code> on PC or <code>Control+Command+F</code> on MacOS.
          </h3>
          <small>
            If you're on a mobile or tablet device or can't increase the screen size, visit simple portfolio page.
          </small>
          <Button onClick={leave}>Leave</Button>
        </>
      )}
      <div className="resolution-scanner">
        <div className="supported-size">
          <div
            className="unsupported-size"
            style={{
              height: `${pixelToRelativeSize(height)}px`,
              width: `${pixelToRelativeSize(width)}px`,
            }}
          >
            {width} x {height}
          </div>
        </div>

        <small>Resize browser until the red square disappears</small>
      </div>
    </div>
  );
};

export default ScreenWarning;
