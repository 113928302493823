import firebase from 'firebase/app';
import 'firebase/firestore';
import DocumentData = firebase.firestore.DocumentData;

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export const FIREBASE_CONFIG: FirebaseConfig = {
  apiKey: 'AIzaSyCmOC41ABjBs83eS-tGWvdX1M7yO2Qt2S8',
  authDomain: 'portfolio-a3fd1.firebaseapp.com',
  projectId: 'portfolio-a3fd1',
  storageBucket: 'portfolio-a3fd1.appspot.com',
  messagingSenderId: '1071338475671',
  appId: '1:1071338475671:web:76ba52c340716e4fb4d9d2',
  measurementId: 'G-XY4X453DDM',
};

const useFirebase = (documentId: string) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_CONFIG);
  }

  const firestore = firebase.firestore();

  const getCollection = () => firestore.collection(documentId);

  const getInnerCollection = (doc: DocumentData, collectionId: string) => doc.collection(collectionId);

  const getDoc = (docId?: string | undefined) => getCollection().doc(docId);

  const push = async (doc: DocumentData, property: string, value: any) => {
    const { arrayUnion } = firebase.firestore.FieldValue;
    await doc.update({ [property]: arrayUnion(value) });
  };

  const cleanUp = async (docId: string, collections: Array<string>) => {
    const doc = getDoc(docId);

    for (const collection in collections) {
      const c = await doc.collection(collection).get();
      c.forEach((snapshot: any) => {
        snapshot.ref.delete();
      });
    }

    await doc.delete();
  };

  return {
    getCollection,
    getDoc,
    push,
    cleanUp,
    getInnerCollection,
  };
};

export default useFirebase;
