import React, { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';

interface DateProps {
  short?: boolean;
}

const Date: FC<DateProps> = ({ short = false }) => {
  const format: string = short ? 'l' : 'dddd, MMMM D, YYYY';

  const [time, setTime] = useState<string>(moment().format(format));
  const interval = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    interval.current = setInterval(() => {
      setTime(moment().format(format));
    }, 60000);

    return () => clearInterval(interval.current);
  }, [format]);

  return <span className="date">{time}</span>;
};

export default Date;
