import React, { CSSProperties, FC } from 'react';

import { ReactComponent as CustomCalculator } from '../../assets/icons/custom_calculator.svg';
import { ReactComponent as CustomNotepad } from '../../assets/icons/custom_notepad.svg';
import { ReactComponent as CustomTicTacToe } from '../../assets/icons/custom_ticTacToe.svg';
import { ReactComponent as CustomJavaScript } from '../../assets/icons/custom_javascript.svg';
import { ReactComponent as CustomHTML } from '../../assets/icons/custom_html.svg';
import { ReactComponent as CustomCSS } from '../../assets/icons/custom_css.svg';
import { ReactComponent as CustomSCSS } from '../../assets/icons/custom_scss.svg';
import { ReactComponent as CustomReact } from '../../assets/icons/custom_react.svg';
import { ReactComponent as CustomNodeJS } from '../../assets/icons/custom_nodejs.svg';
import { ReactComponent as CustomGit } from '../../assets/icons/custom_git.svg';
import { ReactComponent as CustomMap } from '../../assets/icons/custom_map.svg';
import { ReactComponent as CustomPhone } from '../../assets/icons/custom_phone.svg';
import { ReactComponent as CustomMail } from '../../assets/icons/custom_mail.svg';
import { ReactComponent as CustomKroon } from '../../assets/icons/custom_kroon.svg';
import { ReactComponent as CustomTransperfect } from '../../assets/icons/custom_transperfect.svg';
import { ReactComponent as CustomEmisia } from '../../assets/icons/custom_emisia.svg';
import { ReactComponent as CustomUpwork } from '../../assets/icons/custom_upwork.svg';
import { ReactComponent as CustomVideoCall } from '../../assets/icons/custom_videocall.svg';
import { ReactComponent as CustomStories } from '../../assets/icons/custom_stories.svg';
import { ReactComponent as CustomQuoteStart } from '../../assets/icons/custom_quoteStart.svg';
import { ReactComponent as CustomQuoteEnd } from '../../assets/icons/custom_quoteEnd.svg';

type CustomIconProps = {
  type: string;
  style: CSSProperties | undefined;
};

const IconsList: any = {
  custom_calculator: CustomCalculator,
  custom_notepad: CustomNotepad,
  custom_ticTacToe: CustomTicTacToe,
  custom_javascript: CustomJavaScript,
  custom_html: CustomHTML,
  custom_css: CustomCSS,
  custom_scss: CustomSCSS,
  custom_react: CustomReact,
  custom_nodejs: CustomNodeJS,
  custom_git: CustomGit,
  custom_map: CustomMap,
  custom_phone: CustomPhone,
  custom_mail: CustomMail,
  custom_kroon: CustomKroon,
  custom_transperfect: CustomTransperfect,
  custom_emisia: CustomEmisia,
  custom_upwork: CustomUpwork,
  custom_videocall: CustomVideoCall,
  custom_stories: CustomStories,
  custom_quoteStart: CustomQuoteStart,
  custom_quoteEnd: CustomQuoteEnd,
};

const CustomIcon: FC<CustomIconProps> = ({ type, style }) => {
  const Element = IconsList[type];
  return <Element style={style} className='custom-icon' />;
};

export default CustomIcon;
