/* eslint-disable */
// TODO: Simplify the component
import React, { FC, useEffect, useRef, useState } from 'react';

import { replaceAt } from '../../util/helpers';
import { registerCalculatorKeyboardHandlers, unregisterCalculatorKeyboardHandlers } from './util';
import Icon from '../../components/icon/Icon';
import { mpView } from '../../util/Analytics';

enum Variant {
  operation = 'operation',
  number = 'number',
}

const Operations = {
  clear: 'C',
  squared: 'squared',
  percent: '%',
  divide: '/',
  multiply: '*',
  minus: '-',
  plus: '+',
  equals: '=',
};

// TODO: Limit each operand to max 12 digits
// TODO: Handle very large numbers & Infinity
const Calculator: FC = () => {
  const [history, setHistory] = useState<Array<string>>([]);
  const [output, setOutput] = useState<string>('');
  const [result, setResult] = useState<number>(0);

  const handleButtonClick = (event: any) => {
    const { variant, value } = event.target.dataset;

    let newOutput = output.replace('--', '+');
    let newResult;

    if (variant === Variant.operation) {
      if (output.charAt(output.length - 1) === '.') {
        newOutput = output.replace(/.$/, '');
      }

      if (value === Operations.clear) {
        setOutput('');
        setResult(0);
        return;
      }

      if (output === '') return;

      if (value === Operations.equals) {
        if (output.charAt(output.length - 1) === Operations.equals) return;
        newResult = eval(newOutput);
        setOutput(newOutput + '=');
        setResult(Number(newResult.toFixed(2)));
        setHistory([`${newOutput} = ${newResult.toFixed(2)}`, ...history]);
        return;
      }

      if (output.charAt(output.length - 1) === Operations.equals) {
        if (value === Operations.squared) {
          newResult = Number(result) * Number(result);
          newOutput = `sqr(${result})`;
          setOutput(newOutput + '=');
          setResult(Number(newResult.toFixed(2)));
          setHistory([`${newOutput} = ${newResult.toFixed(2)}`, ...history]);
        } else {
          setOutput(result + value);
        }
        return;
      }

      if (Object.values(Operations).includes(newOutput.charAt(newOutput.length - 1))) {
        if (value === Operations.squared) {
          newOutput = output.replace(/.$/, '');
        } else {
          setOutput(newOutput.replace(/.$/, value));
          return;
        }
      }

      if (/[-/+*%]/g.test(newOutput)) {
        if (value === Operations.squared) {
          newResult = Number(eval(newOutput)) * Number(eval(newOutput));
          newOutput = `sqr(${result})`;
          setOutput(newOutput + '=');
          setResult(Number(newResult.toFixed(2)));
          setHistory([`${newOutput} = ${newResult.toFixed(2)}`, ...history]);
          return;
        }

        const operations = ['/', '*', '+', '=', 'C', '-'];
        const outputArr = newOutput.split('');
        if (outputArr[0] === '-' && outputArr.filter(item => operations.includes(item)).length === 1) {
          setOutput(newOutput + value);
          return;
        }

        newResult = eval(newOutput);
        setHistory([`${newOutput} = ${newResult.toFixed(2)}`, ...history]);
        newOutput = newResult + value;
        setOutput(newOutput);
        setResult(Number(newResult.toFixed(2)));
        return;
      }

      if (value === Operations.squared) {
        newResult = Number(newOutput) * Number(newOutput);
        newOutput = `sqr(${newOutput})`;
        setOutput(newOutput + '=');
        setResult(Number(newResult.toFixed(2)));
        setHistory([`${newOutput} = ${newResult.toFixed(2)}`, ...history]);
        return;
      }

      setOutput(newOutput + value);
      return;
    }

    if (newOutput.charAt(newOutput.length - 1) === '.' && value === '0') {
      newOutput = output.replace(/.$/, '');
      setOutput(newOutput);
      return;
    }

    if (value === '+/-') {
      if (newOutput.charAt(newOutput.length - 1) === Operations.equals) {
        newOutput = String(result);
      }
      if (!Object.values(Operations).includes(newOutput.charAt(newOutput.length - 1))) {
        const matches = newOutput.match(/[+-/*%]*\d+(\.\d{1,2})?$/);
        if (matches) {
          const match = matches[0];
          const signs = match.match(/[+-/*%]+/);
          let toReplace = String(Number(match) * -1);
          let index = matches.index || 0;

          if (signs && signs[0] !== '.') {
            index += 1;
            toReplace = String(Number(match.substring(1)) * -1);
          }

          newOutput = replaceAt(newOutput, index, toReplace);
          setOutput(newOutput);
        }

        if (newOutput.charAt(newOutput.length - 1) === Operations.equals) {
          newResult = String(Number(result) * -1);
          setOutput(newResult);
          setResult(Number(result) * -1);
        }

        return;
      }
    }

    if (newOutput.charAt(newOutput.length - 1) === Operations.equals) {
      newResult = eval(newOutput);
      setOutput(String(newResult));
      setResult(Number(newResult.toFixed(2)));
      return;
    }

    setOutput(newOutput + value);
  };

  const cbRef = useRef(handleButtonClick);

  useEffect(() => {
    cbRef.current = handleButtonClick;
  });

  useEffect(() => {
    const cb = (e: any) => cbRef.current(e);
    mpView('calculator');
    registerCalculatorKeyboardHandlers(cb);
    return () => unregisterCalculatorKeyboardHandlers(cb);
  }, []);

  return (
    <div className="calculator">
      <div className="calculator-left">
        <p className="calculator-output">{output}</p>
        <p className="calculator-result">{result}</p>
        <div className="calculator-buttons">
          <button data-variant="operation" data-value="%" onClick={handleButtonClick}>
            %
          </button>
          <button data-variant="operation" data-value="squared" onClick={handleButtonClick}>
            x<sup>2</sup>
          </button>
          <button data-variant="operation" data-value="C" onClick={handleButtonClick}>
            C
          </button>
          <button data-variant="operation" data-value="/" onClick={handleButtonClick}>
            /
          </button>

          <button data-variant="number" data-value="7" onClick={handleButtonClick}>
            7
          </button>
          <button data-variant="number" data-value="8" onClick={handleButtonClick}>
            8
          </button>
          <button data-variant="number" data-value="9" onClick={handleButtonClick}>
            9
          </button>
          <button data-variant="operation" data-value="*" onClick={handleButtonClick}>
            *
          </button>

          <button data-variant="number" data-value="4" onClick={handleButtonClick}>
            4
          </button>
          <button data-variant="number" data-value="5" onClick={handleButtonClick}>
            5
          </button>
          <button data-variant="number" data-value="6" onClick={handleButtonClick}>
            6
          </button>
          <button data-variant="operation" data-value="-" onClick={handleButtonClick}>
            -
          </button>

          <button data-variant="number" data-value="1" onClick={handleButtonClick}>
            1
          </button>
          <button data-variant="number" data-value="2" onClick={handleButtonClick}>
            2
          </button>
          <button data-variant="number" data-value="3" onClick={handleButtonClick}>
            3
          </button>
          <button data-variant="operation" data-value="+" onClick={handleButtonClick}>
            +
          </button>

          <button data-variant="number" data-value="+/-" onClick={handleButtonClick}>
            +/-
          </button>
          <button data-variant="number" data-value="0" onClick={handleButtonClick}>
            0
          </button>
          <button data-variant="number" data-value="." onClick={handleButtonClick}>
            .
          </button>
          <button data-variant="operation" data-value="=" onClick={handleButtonClick}>
            =
          </button>
        </div>
      </div>
      <div className="calculator-right">
        <div className="calculator-history-items">
          <p className="calculator-history-title">History</p>
          {history.map((item, index) => (
            <p className="calculator-history-item" key={`history-item-${index}`}>
              {item}
            </p>
          ))}
        </div>
        <Icon type="DeleteOutlined" onClick={() => setHistory([])} />
      </div>
    </div>
  );
};

export default Calculator;
