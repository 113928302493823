import React, { FC, ReactNode, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Dropdown, Menu, Modal } from 'antd';
import Draggable from 'react-draggable';
import type { DraggableEvent, DraggableData } from 'react-draggable';

import { tm_menuItemClick } from '../../redux/taskManagerSlice';
import Icon from '../icon/Icon';
import useApps, { Toolbar } from '../../apps/appCenter';

interface DialogProps {
  isVisible: boolean;
  onCancel: () => void;
  width: string | number;
  component: FC;
  title: ReactNode;
  maximizable?: boolean;
  toolbar: Toolbar;
  appID: string;
  zIndex: number;
  wrapClassName: string;
}

interface Bounds {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

const Dialog: FC<DialogProps> = ({
  isVisible,
  onCancel,
  width = 60,
  component,
  title,
  maximizable = false,
  toolbar = undefined,
  appID,
  zIndex,
  wrapClassName,
}) => {
  const dispatch = useDispatch();
  const [maximized, setMaximized] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('Untitled');
  const { isActive, setActive, getAppDetails } = useApps();
  const [bounds, setBounds] = useState<Bounds>({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState<boolean>(false);
  const draggableRef = useRef<HTMLDivElement>(null);

  const { editableTitle } = getAppDetails(appID);
  const Component: FC<any> = component;

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }

    if (!isActive(appID)) {
      setActive(appID);
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y) - 60,
    });
  };

  const maximize = () => setMaximized(maxed => !maxed);

  const onMenuItemClick = ({ key }: any) => {
    dispatch(tm_menuItemClick({ appID, key }));
  };

  const changeFileName = (e: React.ChangeEvent<HTMLInputElement>) => setFileName(e.target.value);

  const menu = <Menu items={toolbar} onClick={onMenuItemClick} />;

  const renderTitle = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '1.6rem',
      }}
      onMouseOver={() => {
        if (disabled && !maximized) {
          setDisabled(false);
        }
      }}
      onMouseOut={() => setDisabled(true)}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {editableTitle ? (
          <span className="dialog-title-wrapper">
            {title}-
            <input className="dialog-title-input" value={fileName} onChange={changeFileName} />
          </span>
        ) : (
          title
        )}
        {toolbar && (
          <Dropdown overlay={menu} trigger={['click']}>
            <span style={{ fontSize: '1.6rem', fontWeight: '300', marginTop: '0.5rem' }}>File</span>
          </Dropdown>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {maximizable && (
          <Icon type={maximized ? 'SwitcherOutlined' : 'BorderOutlined'} className="dialog-action" onClick={maximize} />
        )}
        <Icon type="CloseOutlined" className="dialog-action" onClick={() => onCancel()} />
      </div>
    </div>
  );

  return (
    <Modal
      className={maximized ? 'maximized' : 'dialog-regular'}
      wrapClassName={wrapClassName}
      zIndex={zIndex}
      width={width}
      title={renderTitle()}
      visible={isVisible}
      onCancel={onCancel}
      mask={false}
      maskClosable={false}
      keyboard={false}
      footer={null}
      destroyOnClose
      closable={false}
      modalRender={modal => (
        <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggableRef}>{modal}</div>
        </Draggable>
      )}
    >
      {/*// @ts-ignore*/}
      {editableTitle ? <Component fileName={fileName} /> : <Component />}
    </Modal>
  );
};

export default Dialog;
