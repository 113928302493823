import React, { FC, useEffect, useRef, useState } from 'react';
import { Input, InputRef, Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons/lib';
import { checkParentsForClass } from '../../util/helpers';
import SearchResults from './SearchResults';

const Search: FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const inputRef = useRef<InputRef>(null);

  const getClassName = () => {
    let cn = 'taskbar-search';

    if (collapsed) {
      cn += ' taskbar-search--collapsed';
    }

    return cn;
  };

  const handleOutsideClick = ({ target }: any) => {
    if (target?.className?.includes('ant-popover')) return;
    if (!checkParentsForClass(target, 'taskbar-search')) setCollapsed(false);
  };

  const handleEsc = (event: KeyboardEvent) => {
    if (event.code === 'Escape') setCollapsed(false);
  };

  useEffect(() => {
    if (collapsed) {
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', handleEsc);
    } else {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleEsc);
      setTimeout(() => {
        setSearchTerm('');
      }, 250);
    }
  }, [collapsed]);

  useEffect(() => {
    if (collapsed) inputRef.current?.focus();
  }, [collapsed]);

  return (
    <div className={getClassName()} onClick={() => setCollapsed(true)}>
      <Popover
        content={<SearchResults term={searchTerm} />}
        align={{ offset: [0, 8] }}
        visible={collapsed && !!searchTerm}
      >
        <Input
          ref={inputRef}
          addonBefore={<SearchOutlined />}
          placeholder="Search for apps..."
          maxLength={30}
          value={searchTerm}
          onChange={({ target }) => setSearchTerm(target.value)}
        />
      </Popover>
    </div>
  );
};

export default Search;
