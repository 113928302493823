import React, { FC } from 'react';
import { StoryTextProps } from './StoryText';
import Icon from '../../../components/icon/Icon';

const StoryQuote: FC<StoryTextProps> = ({ children, style }) => {
  return (
    <div className="story-quote" style={style}>
      <Icon type="custom_quoteStart" className="quote-icon" />
      {children}
      <Icon type="custom_quoteEnd" className="quote-icon" />
    </div>
  );
};

export default StoryQuote;
