import React, { FC, memo } from 'react';
import { Image } from 'antd';
import { parseWithUrls, parseEmbeddableLinks, EmbeddableLink } from '../../util/helpers';

interface CommentContentProps {
  text: string;
}

const CommentContent: FC<CommentContentProps> = ({ text }) => {
  return (
    <>
      <p dangerouslySetInnerHTML={parseWithUrls(text)} />
      {parseEmbeddableLinks(text).map((data: EmbeddableLink, index: number) => {
        if (data.img) {
          return (
            <Image key={`youtube-iframe-${data.id}-${index}`} src={data.img} alt="Pasted" className="chat-iframe" />
          );
        }

        return (
          <iframe
            key={`youtube-iframe-${data.id}-${index}`}
            className="chat-iframe"
            title="YouTube Video"
            src={data.url}
            allowFullScreen
          />
        );
      })}
    </>
  );
};

export default memo(CommentContent);
