import React, { FC } from 'react';
import { Timeline } from 'antd';
import Icon from '../icon/Icon';

const { Item } = Timeline;

const Experience: FC = () => {
  return (
    <div className='widget-experience'>
      <Timeline>
        <Item dot={<Icon type='custom_kroon' />}>
          <h3>Lead Software Engineer</h3>
          <span>Kroon Studio</span>
          <small>Nov 2020 - Present</small>
        </Item>
        <Item dot={<Icon type='custom_transperfect' />}>
          <h3>Software Engineer</h3>
          <span>Transperfect</span>
          <small>May 2019 - Nov 2020</small>
        </Item>
        <Item dot={<Icon type='custom_emisia' />}>
          <h3>Software Engineer</h3>
          <span>Emisia Consulting</span>
          <small>Jun 2018 - May 2019</small>
        </Item>
        <Item dot={<Icon type='custom_upwork' />}>
          <h3>Software Engineer</h3>
          <span>Upwork (freelance)</span>
          <small>Mar 2015 - Mar 2019</small>
        </Item>
      </Timeline>
    </div>
  );
};

export default Experience;
