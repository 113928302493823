import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoardType } from '../apps/ticTacToe/types';

interface TicTacToeState {
  isGameEnd: boolean;
  xWinCounter: number;
  oWinCounter: number;
  drawCounter: number;
  squares: BoardType;
  isTurnX: boolean;
  isPvP: boolean;
  player: string;
  isTurnAI: boolean;
  winner: string;
}

const initialState: TicTacToeState = {
  isGameEnd: false,
  xWinCounter: 0,
  oWinCounter: 0,
  drawCounter: 0,
  squares: Array(9).fill(null),
  isTurnX: true,
  isPvP: false,
  player: '',
  isTurnAI: false,
  winner: '',
};

export const ticTacToeSlice = createSlice({
  name: 'ticTacToe',
  initialState: { ...initialState },
  reducers: {
    ttt_setXWin: state => {
      return {
        ...state,
        xWinCounter: state.xWinCounter + 1,
        isGameEnd: true,
        isTurnX: false,
        isTurnAI: state.player === 'x',
        winner: 'x',
      };
    },
    ttt_setOWin: state => {
      return {
        ...state,
        oWinCounter: state.oWinCounter + 1,
        isGameEnd: true,
        isTurnX: true,
        isTurnAI: state.player === 'o',
        winner: 'o',
      };
    },
    ttt_setDraw: state => {
      return {
        ...state,
        drawCounter: state.drawCounter + 1,
        isGameEnd: true,
        isTurnAI: (!state.isTurnX && state.player === 'x') || (state.isTurnX && state.player === 'o'),
        winner: '',
      };
    },
    ttt_resetGameState: () => {
      return { ...initialState };
    },
    ttt_setGameMode: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isPvP: payload,
      };
    },
    ttt_setAITurn: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isTurnAI: payload,
      };
    },
    ttt_makeMove: (state, { payload }: PayloadAction<BoardType>) => {
      return {
        ...state,
        squares: payload,
        isTurnX: !state.isTurnX,
      };
    },
    ttt_setWhoMovesFirst: (state, { payload }: PayloadAction<string>) => {
      const isTurnX = payload === 'x';
      return {
        ...state,
        isTurnX,
        squares: Array(9).fill(null),
        isGameEnd: false,
        player: (isTurnX && !state.isTurnAI) || (!isTurnX && state.isTurnAI) ? 'x' : 'o',
      };
    },
    ttt_createNewGame: state => {
      return {
        ...state,
        isGameEnd: false,
        squares: Array(9).fill(null),
        player: (state.isTurnX && !state.isTurnAI) || (!state.isTurnX && state.isTurnAI) ? 'x' : 'o',
      };
    },
  },
});

export const {
  ttt_setXWin,
  ttt_setOWin,
  ttt_setDraw,
  ttt_createNewGame,
  ttt_resetGameState,
  ttt_setWhoMovesFirst,
  ttt_makeMove,
  ttt_setAITurn,
} = ticTacToeSlice.actions;
export default ticTacToeSlice.reducer;
