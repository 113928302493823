import React, { FC } from 'react';
import { Menu } from 'antd';
import useApps from '../../apps/appCenter';
import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled, PlusCircleFilled } from '@ant-design/icons/lib';
import { compact } from 'lodash';
import { Fn } from '../../util/types';

interface AppMenuProps {
  appID: string;
  closeMenu: Fn;
}

const AppMenu: FC<AppMenuProps> = ({ appID, closeMenu }) => {
  const { openApp, setActive, openAppInfo, isRunning, closeApp } = useApps();

  const handleMenuClick = ({ key }: any) => {
    if (key === 'openApp') openApp(appID);
    if (key === 'setActive') setActive(appID);
    if (key === 'openAppInfo') openAppInfo(appID);
    if (key === 'closeApp') closeApp(appID);
    closeMenu();
  };

  return (
    <Menu
      selectable={false}
      onClick={handleMenuClick}
      items={compact([
        !isRunning(appID) && {
          label: 'Open',
          key: 'openApp',
          icon: <PlusCircleFilled />,
          disabled: isRunning(appID),
        },
        isRunning(appID) && {
          label: 'Set Active',
          key: 'setActive',
          icon: <CheckCircleFilled />,
          disabled: !isRunning(appID),
        },
        {
          label: 'About',
          key: 'openAppInfo',
          icon: <InfoCircleFilled />,
        },
        isRunning(appID) && {
          label: 'Close',
          key: 'closeApp',
          icon: <CloseCircleFilled />,
          disabled: !isRunning(appID),
        },
      ])}
    />
  );
};

export default AppMenu;
