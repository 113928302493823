import React, { FC, useState } from 'react';
import AppMenu from '../contextMenu/appMenu';
import Icon from '../icon/Icon';
import { Dropdown } from 'antd';
import useApps, { AppProps } from '../../apps/appCenter';

interface FooterIconProps {
  app: AppProps;
  className: string;
}

const FooterIcon: FC<FooterIconProps> = ({ app, className }) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const { toggleApp } = useApps();

  const onVisibleChange = (isVisible: boolean) => {
    setIsMenuVisible(isVisible);
  };
  return (
    <Dropdown
      overlay={<AppMenu appID={app.appID} closeMenu={() => setIsMenuVisible(false)} />}
      trigger={['contextMenu']}
      key={`footer-shortcut-${app.appID}`}
      visible={isMenuVisible}
      onVisibleChange={onVisibleChange}
    >
      <div>
        <Icon
          key={`footer-icon-${app.appID}`}
          type={app.icon}
          className={className}
          onClick={() => toggleApp(app.appID)}
        />
      </div>
    </Dropdown>
  );
};

export default FooterIcon;
