import React, { FC } from 'react';
import STORIES from '../storyList';
import { Card } from 'antd';
import moment from 'moment';

interface HomeProps {
  setPage: (page: string) => void;
}

const Home: FC<HomeProps> = ({ setPage }) => {
  return (
    <div className="stories-home">
      {STORIES.map((story: any) => (
        <Card
          key={`story-card-${story.id}`}
          hoverable
          cover={<img src={story.image} alt="featured" />}
          style={{ width: '19rem', height: 'fit-content' }}
          onClick={() => setPage(story.id)}
        >
          <Card.Meta title={story.label} description={moment(story.date).fromNow()} />
        </Card>
      ))}
    </div>
  );
};

export default Home;
