import React, { FC, useState } from 'react';
import AppMenu from '../contextMenu/appMenu';
import Icon from '../icon/Icon';
import { Dropdown } from 'antd';
import useApps, { AppProps } from '../../apps/appCenter';

interface ShortcutProps {
  app: AppProps;
}

const Shortcut: FC<ShortcutProps> = ({ app }) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const { openApp } = useApps();

  const onVisibleChange = (isVisible: boolean) => {
    setIsMenuVisible(isVisible);
  };

  return (
    <Dropdown
      className="desktop-shortcut"
      overlay={<AppMenu appID={app.appID} closeMenu={() => setIsMenuVisible(false)} />}
      trigger={['contextMenu']}
      key={`desktop-shortcut-${app.appID}`}
      visible={isMenuVisible}
      onVisibleChange={onVisibleChange}
    >
      <div onDoubleClick={() => openApp(app.appID)}>
        <Icon type={app.icon} className="desktop-icon" />
        <span className="shortcut-label">{app.appName}</span>
      </div>
    </Dropdown>
  );
};

export default Shortcut;
