import React, { FC } from 'react';
import Icon from '../icon/Icon';
import { Dropdown, Menu } from 'antd';
import { FrownFilled, PoweroffOutlined, ReloadOutlined } from '@ant-design/icons/lib';
import { useDispatch } from 'react-redux';
import { ac_restart, ac_shutDown } from '../../redux/appControlSlice';
import useApps, { AppProps } from '../../apps/appCenter';
import shutdownAudioRaw from '../../assets/sounds/shutdown.mp3';
import { useAudio } from '../../util/useAudio';

const StartMenu: FC = () => {
  const { apps, openApp, isRunning, setActive, isActive } = useApps();
  const dispatch = useDispatch();
  const { audio } = useAudio(shutdownAudioRaw);

  const handleMenuClick = ({ key }: any) => {
    if (key === 'restart') {
      dispatch(ac_restart());
      audio.play();
      return;
    }

    if (key === 'shutdown') {
      dispatch(ac_shutDown());
      audio.play();
      return;
    }

    if (key === 'openSimplePortfolio') {
      window.open('https://simple.nbojanovic.dev/');
      return;
    }

    if (isRunning(key)) {
      if (!isActive(key)) setActive(key);
      return;
    }

    openApp(key);
  };

  const startMenuApps = apps.map((app: AppProps) => {
    return {
      key: app.appID,
      label: app.appName,
      icon: <Icon type={app.icon} style={{ height: '1.8rem', width: '1.8rem', fontSize: '1.8rem' }} />,
    };
  });

  const startMenu = (
    <div className="start-menu">
      <Menu
        selectable={false}
        onClick={handleMenuClick}
        items={[
          ...startMenuApps,
          {
            type: 'divider',
          },
          {
            key: 'openSimplePortfolio',
            label: 'Open Simple Portfolio',
            icon: <FrownFilled />,
          },
          {
            key: 'restart',
            label: 'Restart',
            icon: <ReloadOutlined />,
          },
          {
            key: 'shutdown',
            label: 'Shut Down',
            icon: <PoweroffOutlined />,
          },
        ]}
      />
    </div>
  );

  return (
    <Dropdown overlay={startMenu} trigger={['click']} placement="topLeft" overlayClassName="start-menu-container">
      <Icon type="WindowsFilled" className="footer-icon" />
    </Dropdown>
  );
};

export default StartMenu;
