import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ttt_setWhoMovesFirst } from '../../redux/ticTacToeSlice';
import { useAudio } from '../../util/useAudio';
import clickSound from './sounds/click.mp3';
import { Piece } from './types';

const PieceChooser: FC = () => {
  const dispatch = useDispatch();
  const { audio } = useAudio(clickSound);

  const choosePiece = (piece: Piece) => {
    dispatch(ttt_setWhoMovesFirst(piece));
    audio.play();
  };

  return (
    <div className="piece-chooser-wrap">
      <h1>Let's play a game of Tic Tac Toe!</h1>
      <h2>Choose your piece</h2>
      <div className="piece-chooser">
        <div className="ttt-btn-wrapper" onClick={() => choosePiece(Piece.x)}>
          <button className="ttt-btn">X</button>
        </div>
        <div className="ttt-btn-wrapper" onClick={() => choosePiece(Piece.o)}>
          <button className="ttt-btn">O</button>
        </div>
      </div>
    </div>
  );
};

export default PieceChooser;
