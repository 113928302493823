import React, { FC, ReactNode } from 'react';

import useApps from '../../apps/appCenter';
import DateTime from './DateTime';
import StartMenu from '../startMenu/StartMenu';
import Search from './Search';
import FooterIcon from './FooterIcon';

const Footer: FC = () => {
  const { isActive, isRunning, apps } = useApps();

  return (
    <div className="footer">
      <div className="taskbar">
        <StartMenu />
        <Search />

        {apps.reduce((acc: Array<ReactNode>, curr) => {
          if (curr.taskBar || isRunning(curr.appID)) {
            const className = `footer-icon app-icon ${isActive(curr.appID) ? 'app-icon-active' : ''}${
              isRunning(curr.appID) ? ' app-icon-running' : ''
            }`;

            acc.push(<FooterIcon app={curr} className={className} key={`footer-icon-${curr.appID}`} />);
          }

          return acc;
        }, [])}
      </div>

      <DateTime />
    </div>
  );
};

export default Footer;
