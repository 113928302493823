export enum Piece {
  x = 'x',
  o = 'o',
}

export type BoardPosition = null | Piece;

export type BoardType = Array<BoardPosition>;

export interface GameBoard {
  isWin: () => boolean;
  isDraw: () => boolean;
  oppositeTurn: () => Piece;
  legalMoves: () => Array<number>;
  move: (location: number) => GameBoard;
  turn: Piece;
}
