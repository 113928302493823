import React, { FC, useEffect, useState } from 'react';

import { Progress } from 'antd';

const AppLoading: FC = () => {
  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    setInterval(() => {
      setPercent(p => p + 1);
    }, 40);
  }, []);

  const getLoadingText = () => {
    if (percent <= 25) return 'Initializing system';
    if (percent <= 50) return 'Installing applications';
    if (percent <= 75) return 'Applying configuration';
    return 'Starting, please wait';
  };

  return (
    <div className="appLoading">
      <div className="progress-wrapper">
        {getLoadingText()}
        <Progress percent={percent} />
      </div>
    </div>
  );
};

export default AppLoading;
