import React, { FC } from 'react';
import StoryText from './StoryText';
import { isDev } from '../../../util/helpers';

interface StoryFooterProps {
  setPage: (page: string) => void;
  relevantStories: Array<{ name: string; page: string }>;
}

const StoryFooter: FC<StoryFooterProps> = ({ setPage, relevantStories }) => {
  // TODO: Uncomment once story linking is available
  // const onLinkClick = (event: any, page: string) => {
  //     event.preventDefault();
  //     setPage(page);
  // };
  if (!isDev()) {
    console.log(relevantStories, setPage); // TODO: To get rid of the eslint error => Remove after relevant links implementation
  }
  return (
    <StoryText>
      Hope this story was helpful to you. Feel free to reach out{' '}
      <a href="mailto:nbojanovic.dev@gmail.com">nbojanovic.dev@gmail.com</a>.
      <br />
      {/*TODO: Uncomment once story linking is available*/}
      {/*Relevant stories:*/}
      {/*{relevantStories.map((story: any) => <a key={`story-link-${story.page}`} href="/" style={{ display: 'block' }} onClick={(e) => onLinkClick(e, story.page)}>{story.name}</a>)}*/}
    </StoryText>
  );
};

export default StoryFooter;
