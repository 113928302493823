import React, { FC, MouseEventHandler } from 'react';

import * as IconList from '@ant-design/icons';
import CustomIcon from './Custom';

interface IconProps {
  type: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
  title?: string;
}

const Icon: FC<IconProps> = ({ type, className, style, onClick, title }) => {
  const getElement = () => {
    if (type.includes('custom')) {
      return <CustomIcon type={type} style={style} />;
    } else {
      const Element: any = IconList[type as keyof typeof IconList];
      return <Element />;
    }
  };
  return (
    <div
      className={className || 'icon'}
      onClick={onClick}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
      title={title}
    >
      {getElement()}
    </div>
  );
};

export default Icon;
