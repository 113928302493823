import React, { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';

interface ClockProps {
  showSeconds?: boolean;
}

const Clock: FC<ClockProps> = ({ showSeconds = false }) => {
  const format: string = showSeconds ? 'LTS' : 'LT';

  const [time, setTime] = useState<string>(moment().format(format));
  const interval = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    interval.current = setInterval(() => {
      setTime(moment().format(format));
    }, 1000);

    return () => clearInterval(interval.current);
  }, [format]);

  return <span className="clock">{time}</span>;
};

export default Clock;
