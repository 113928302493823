import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { remove } from 'lodash';

interface TaskManagerState {
  running: Array<string>;
  active: string | null;
  menu: Record<string, string>;
}
export const taskManagerSlice = createSlice({
  name: 'taskManager',
  initialState: {
    running: [],
    active: null,
    menu: {},
  } as TaskManagerState,
  reducers: {
    tm_push: (state, { payload }: PayloadAction<string>) => {
      state.running.push(payload);
      return state;
    },
    tm_pop: (state, { payload }: PayloadAction<string>) => {
      remove(state.running, app => app === payload);
      return state;
    },
    tm_menuItemClick: (state, { payload }: PayloadAction<{ appID: string; key: string }>) => {
      state.menu[payload.appID] = payload.key;
      return state;
    },
    tm_clearMenuItemClick: (state, { payload }: PayloadAction<string>) => {
      delete state.menu[payload];
      return state;
    },
    tm_setActive: (state, { payload }: PayloadAction<string>) => {
      state.active = payload;
      return state;
    },
    tm_removeActive: state => {
      state.active = null;
      return state;
    },
  },
});

export const { tm_push, tm_pop, tm_menuItemClick, tm_clearMenuItemClick, tm_setActive, tm_removeActive } =
  taskManagerSlice.actions;
export default taskManagerSlice.reducer;
