import React, { CSSProperties, FC } from 'react';

interface StoryImageProps {
  src: string;
  style?: CSSProperties;
}

const StoryImage: FC<StoryImageProps> = ({ src, style }) => {
  return <img src={src} className="story-image" alt="story" style={style} />;
};

export default StoryImage;
