export const replaceAt = (source: string, index: number | undefined = 0, replacement: string): string => {
  return source.substring(0, index) + replacement + source.substring(index + replacement.length);
};

export const downloadTxt = (text: string, fileName: string) => {
  const element = document.createElement('a');
  const file = new Blob([text], {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadPdf = (filePath: string, fileName: string) => {
  const element = document.createElement('a');
  element.href = filePath;
  element.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const checkParentsForClass = (element: HTMLElement, className: string): string | null => {
  if (element?.className?.includes(className)) return element.className;

  return element?.parentElement && checkParentsForClass(element.parentElement, className);
};

export interface EmbeddableLink {
  url?: string;
  id: string;
  img?: string;
}
export const parseEmbeddableLinks = (text: string): Array<EmbeddableLink> => {
  const embeddableLinks: Array<EmbeddableLink> = [];
  const youTubeVideosUrls: Array<RegExpMatchArray> = Array.from(
    text.matchAll(/(https:\/\/www.youtube.com\/watch\?v=\S*|https:\/\/youtu.be\/\S*)/g),
  );
  const gifUrls: Array<RegExpMatchArray> = Array.from(text.matchAll(/https:\/\/media.giphy.com\S*.gif/g));
  const images: Array<RegExpMatchArray> = Array.from(text.matchAll(/data:image\S*/g));

  if (youTubeVideosUrls?.length) {
    youTubeVideosUrls.forEach(link => {
      let videoID: string;
      if (link.input?.includes('https://www.youtube.com')) {
        videoID = link[0].split('v=')[1];
      } else {
        videoID = link[0].split('youtu.be')[1];
      }
      embeddableLinks.push({
        url: `https://www.youtube.com/embed/${videoID}`,
        id: `${videoID}-${new Date().getTime()}`,
      });
    });
  }

  if (gifUrls?.length) {
    gifUrls.forEach(link => {
      const gifID: string = link[0].split('media/')[1].split('/giphy.gif')[0];
      embeddableLinks.push({
        url: `https://giphy.com/embed/${gifID}`,
        id: `${gifID}-${new Date().getTime()}`,
      });
    });
  }

  if (images?.length) {
    images.forEach(img => {
      embeddableLinks.push({ img: img.input, id: `pasted-img-${new Date().getTime()}` });
    });
  }

  return embeddableLinks;
};

export const parseWithUrls = (text: string) => {
  // eslint-disable-next-line
  const regex: RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi;
  const normalizedText: string = text.replaceAll(/data:image\S*/g, 'Image: ');
  const parsedText: string = normalizedText.replaceAll(regex, url => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
  return {
    __html: parsedText,
  };
};

export const isDev = () => process.env.NODE_ENV === 'development';
