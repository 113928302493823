import { createSlice } from '@reduxjs/toolkit';

interface AppControlState {
  isLoading: boolean;
  powerOn: boolean;
}

export const appControlSlice = createSlice({
  name: 'appControl',
  initialState: {
    isLoading: false,
    powerOn: false,
  } as AppControlState,
  reducers: {
    ac_shutDown: state => {
      return {
        ...state,
        powerOn: false,
        isLoading: false,
      };
    },
    ac_turnOn: state => {
      return {
        ...state,
        powerOn: true,
        isLoading: true,
      };
    },
    ac_restart: state => {
      return {
        ...state,
        isLoading: true,
      };
    },
    ac_loaded: state => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const { ac_shutDown, ac_restart, ac_loaded, ac_turnOn } = appControlSlice.actions;
export default appControlSlice.reducer;
