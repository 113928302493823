import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import 'antd/dist/antd.dark.min.css';
import './assets/style/index.scss';

import store from './redux/store';

import App from './App';
import ScreenControl from './util/ScreenControl';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ScreenControl>
      <App />
    </ScreenControl>
  </Provider>,
);
