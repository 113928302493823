import React, { FC } from 'react';
import moment from 'moment';

interface StoryDateProps {
  date: string;
}

const StoryDate: FC<StoryDateProps> = ({ date }) => {
  return (
    <div className="story-date">
      <span>Nikola Bojanović</span>
      <small>{moment(date).format('MMM Do YYYY')}</small>
    </div>
  );
};

export default StoryDate;
