import React, { FC, useEffect } from 'react';

import { checkParentsForClass } from '../../util/helpers';
import useApps from '../../apps/appCenter';
import { useAudio } from '../../util/useAudio';
import startUpAudioRaw from '../../assets/sounds/startup.mp3';
import Shortcut from './Shortcut';
import Widgets from '../widgets/Widgets';
import { mpView } from '../../util/Analytics';

const Desktop: FC = () => {
  const { apps, setActive } = useApps();
  const { audio } = useAudio(startUpAudioRaw);

  useEffect(() => {
    audio.play();
    mpView('desktop');
    document.addEventListener('click', ({ target }: any) => {
      if (target?.className !== 'dialog-action') {
        const withinDialog = checkParentsForClass(target, 'appID');
        if (withinDialog) {
          const appClassName = withinDialog.split(' ').find(item => item.includes('appID'));
          if (appClassName) {
            const appID = appClassName.split('appID-')[1];
            setActive(appID);
          }
        }
      }
    });
    // Intentionally prevent rerunning
    // eslint-disable-next-line
  }, []);

  return (
    <div className="desktop">
      <div className="desktop-shortcuts">
        {apps.map(app => (
          <Shortcut app={app} key={app.appID} />
        ))}
      </div>
      <Widgets />
    </div>
  );
};

export default Desktop;
