import React, { CSSProperties, FC, ReactNode } from 'react';
import { StoryTextProps } from './StoryText';
import { Divider } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

interface StoryListProps extends StoryTextProps {
  title?: string;
  inner?: boolean;
  style?: CSSProperties;
}

const StoryList: FC<StoryListProps> = ({ children, title, inner = false, style }) => {
  return (
    <div className="story-list-wrapper" style={style}>
      {title && (
        <>
          <h4>{title}</h4>
          <Divider />
        </>
      )}
      <div className="story-list">
        {React.Children.map(children, (element: ReactNode) => (
          <div className="story-list-item">
            {inner ? '•' : <CheckCircleFilled />}
            {element}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoryList;
