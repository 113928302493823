import { configureStore } from '@reduxjs/toolkit';

import taskManagerReducer from './taskManagerSlice';
import appControlReducer from './appControlSlice';
import ticTacToeReducer from './ticTacToeSlice';

export type RootStore = ReturnType<typeof store.getState>;

const store = configureStore({
  reducer: {
    taskManager: taskManagerReducer,
    appControl: appControlReducer,
    ticTacToe: ticTacToeReducer,
  },
});
export default store;
