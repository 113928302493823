import React, { FC } from 'react';
import { Button, Divider, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons/lib';
import { CurrentPage } from './VideoCall';
import { EVENT, mpEvent } from '../../util/Analytics';

interface VideoCallMenuProps {
  joinCode: string;
  setJoinCode: (code: string) => void;
  setPage: (page: CurrentPage) => void;
  username: string;
  setUsername: (username: string) => void;
  localStream: MediaStream;
}

const VideoCallMenu: FC<VideoCallMenuProps> = ({
  joinCode,
  setJoinCode,
  setPage,
  username,
  setUsername,
  localStream,
}) => {
  return (
    <div className="video-call-menu">
      <h4>{!localStream && 'Turn on your camera and microphone and start the call'}</h4>
      <Input
        value={username}
        prefix={<UserOutlined />}
        placeholder="Enter your name"
        onChange={(e: any) => setUsername(e.target.value)}
      />
      <Divider />
      <Button
        disabled={!username || !localStream.active}
        onClick={() => {
          mpEvent(EVENT.chatter, 'started_video_call');
          setPage(CurrentPage.create);
        }}
      >
        Create Room
      </Button>
      <span className="divider">or</span>
      <div className="video-call-join">
        <Input value={joinCode} placeholder="Enter room id" onChange={(e: any) => setJoinCode(e.target.value)} />
        <Button
          disabled={!username || !joinCode || !localStream.active}
          onClick={() => {
            mpEvent(EVENT.chatter, 'joined_video_call');
            setPage(CurrentPage.join);
          }}
        >
          Join room
        </Button>
      </div>
    </div>
  );
};

export default VideoCallMenu;
