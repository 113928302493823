import React, { FC } from 'react';

import { Button } from 'antd';
import { downloadPdf } from '../../util/helpers';
import cvPDF from '../../assets/pdf/Nikola Bojanovic - CV.pdf';
import { EVENT, mpEvent } from '../../util/Analytics';

const AboutMe: FC = () => {
  const downloadCV = () => {
    mpEvent(EVENT.download, 'cv');
    downloadPdf(cvPDF, 'Nikola Bojanovic - CV');
  };

  return (
    <div className="widget-aboutMe">
      <h2>Nikola Bojanović</h2>
      <Button className="widget-aboutMe-cta" onClick={downloadCV}>
        Download CV
      </Button>
      <p>Senior Software Engineer with exhibited history in building secure, robust and sustainable solutions.</p>
      <ul>
        <li>
          Conducted hundreds of technical interviews and participated in most stages of the recruitment process.
          Providing value to candidates has directly led to positive feedback and increased company score.{' '}
        </li>
        <li>
          Led small to medium sized teams. During my tenure, I protected and educated teammates. Leaders give away the
          credit when things go well and take responsibility when things go badly (by S.S){' '}
        </li>
        <li>
          Adept at Front-end architectural structure and design systems. Created an in-depth strategy to refactor and
          secure a large monolithic application.{' '}
        </li>
        <li>
          Versed at developer friendly automation and pipelines (eslint, prettier, test, code generators, github CI/CD).
          Built numerous tools that ward off majority of bugs and inconsistencies.
        </li>
        <li>Reviewed thousands pull requests learning and sharing knowledge with other developers.</li>
      </ul>
    </div>
  );
};

export default AboutMe;
