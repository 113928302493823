import React, { FC } from 'react';

import { RelevantStories } from '../Stories';
import { PageProps } from '../Story';

import StoryFooter from '../components/StoryFooter';
import StoryText from '../components/StoryText';
import StoryList from '../components/StoryList';

const relevantStories: RelevantStories = [
  {
    name: 'Burnout',
    page: 'burnout',
  },
  {
    name: 'Pretty Prettier',
    page: 'prettyPrettier',
  },
  {
    name: 'Before You Start Coding',
    page: 'beforeYouStartCoding',
  },
];

const SideProjects: FC<PageProps> = ({ setPage }) => {
  return (
    <>
      <StoryText>
        The majority of us Software Engineers have a job that we do to earn money. More often than not, we're working on
        the project that isn't interesting to us as we would like it to be. Usually we have to deal with the outdated
        technologies, legacy code, weird style-guides and complicated processes. The larger and older the project, the
        more biased it is... which is, to an extent, mandatory to keep it maintainable and scalable.
      </StoryText>
      <StoryText>
        Some engineers are truly passionate about code and will enjoy building any project. Even if you're one of those,
        you'll probably get bored after a while. The longer you stick to a single project, the more repetitive it gets.
        Building side projects can help with that!
      </StoryText>
      <StoryList title="The benefits of side projects:">
        <StoryText>
          <strong>Learning.</strong> Working for a company often forces you to stick to a certain technology stack. In
          the beginning, you'll learn the stack, business logic, the product, processes, design pattern...etc. After a
          while you'll enter the "autopilot" mode, where you won't have to think much on how to approach tasks and
          features. The most of the structure is already laid out, all you have to do is follow it. If you're eager to
          play around with some other technologies, the fastest way is to build something by yourself. You can choose
          the stack, the architecture, the code style and the topic. You can (and will) make mistakes but these mistakes
          won't cost money or hurt the customers.
        </StoryText>
        <StoryText>
          <strong>Passion.</strong> Its natural to perform better when doing things you personally enjoy. If you're
          passionate about some topic, you could build a side project about it. It could also be a productivity tool...
          something that you would like to use. Even better if it poses a challenge. While you research and test
          concepts and ideas you'll learn so many things without even noticing.
        </StoryText>
        <StoryText>
          <strong>Prevents burnout.</strong> When you move away from that nasty jQuery code into all new and shiny
          cutting edge framework, it feels good. You have the freedom to use any framework or library and play with it
          to your hearth content. Personally, I get a solid boost of{' '}
          <a href="https://en.wikipedia.org/wiki/Serotonin" target="_blank" rel="noreferrer">
            serotonin
          </a>{' '}
          and{' '}
          <a href="https://en.wikipedia.org/wiki/Dopamine" target="_blank" rel="noreferrer">
            dopamine
          </a>{' '}
          when I build something fun. Warming sense of pride and fullness.
        </StoryText>
        <StoryText>
          <strong>Monetization.</strong> Along with all previously mentioned benefits, it could also become a
          significant source of the income. Sky is the limit. I'll give a few examples:
          <StoryList inner style={{ marginTop: '1.5rem' }}>
            <StoryText>
              Web scrape a bunch of data for a certain topic. Generate a database from that data. Create API that people
              can use and publish it on, for example open api.
            </StoryText>
            <StoryText>
              Keep track of your projects and note any challenging parts or write a diary about the progress. You could
              use it to write blog articles and monetize them.
            </StoryText>
            <StoryText>
              Start a streaming or youtube channel. You could make tutorials, live coding sessions, Q/A sessions on live
              stream or just talk about your journey.
            </StoryText>
            <StoryText>Create courses and use some of the side projects as examples.</StoryText>
          </StoryList>
        </StoryText>
      </StoryList>
      <StoryText>
        I've been in software development industry for quite a while. A lot of things changed but one didn't. I've been
        always working on side projects. Actually, this platform you're using right now started as a side project. I
        wanted to see how difficult would it be to build a Microsoft Windows clone in the browser. At first it was just
        a start menu with clock and desktop. Made it in such a way that I can always add project to it as an app within
        the "Windows". Liked so much how it turned out, that I made it a portfolio page.
      </StoryText>
      <StoryText>Keep visiting the platform, I might "install" something fun ;)</StoryText>
      <StoryFooter setPage={setPage} relevantStories={relevantStories} />
    </>
  );
};

export default SideProjects;
