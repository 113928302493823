import React, { FC } from 'react';
import { Tabs } from 'antd';
import useApps from '../../apps/appCenter';
import Icon from '../icon/Icon';

const { TabPane } = Tabs;

const Widgets: FC = () => {
  const { widgets } = useApps();

  return (
    <div className="widget">
      <Tabs animated>
        {widgets.map(widget => {
          const Component: FC<any> = widget.component;
          return (
            <TabPane
              tab={
                <div className="widget-title">
                  <Icon type={widget.icon} title={widget.name} className="widget-icon" />
                  <span>{widget.name}</span>
                </div>
              }
              key={widget.id}
            >
              <div className="widget-wrapper">
                <Component />
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default Widgets;
