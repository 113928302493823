import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { Progress } from 'antd';

type TimerProps = {
  onTimeOut: () => void;
};

const Timer: FC<TimerProps> = ({ onTimeOut }) => {
  const [timer, setTimer] = useState<number>(10000);
  const timerID = useRef<ReturnType<typeof setInterval>>();

  const resetTimer = () => {
    clearInterval(timerID.current);
    setTimer(10000);
  };

  useEffect(() => {
    if (timer <= 0) onTimeOut();
  }, [timer, onTimeOut]);

  useEffect(() => {
    timerID.current = setInterval(() => {
      setTimer(t => t - 100);
    }, 100);

    return () => {
      resetTimer();
    };
  }, []);

  return <Progress percent={timer / 100} showInfo={false} strokeColor="#87d068" className="timer" />;
};

export default memo(Timer);
