import React, { CSSProperties, FC, ReactNode } from 'react';

export interface StoryTextProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const StoryText: FC<StoryTextProps> = ({ children, className, style }) => {
  return (
    <p className={`story-text ${className || ''}`} style={style}>
      {children}
    </p>
  );
};

export default StoryText;
