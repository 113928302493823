import React, { FC, useEffect } from 'react';
import { StoryType } from './storyList';
import StoryTitle from './components/StoryTitle';
import StoryDate from './components/StoryDate';
import { EVENT, mpEvent } from '../../util/Analytics';

interface StoryProps {
  story: StoryType;
  setPage: (page: string) => void;
}

export interface PageProps {
  setPage: (page: string) => void;
}

const Story: FC<StoryProps> = ({ story, setPage }) => {
  const Page: FC<any> = story.component;

  useEffect(() => {
    mpEvent(EVENT.view_story, story.label);
  }, [story.label]);

  return (
    <div className="story">
      <StoryTitle image={story.image}>{story.label}</StoryTitle>
      <Page setPage={setPage} />
      <StoryDate date={story.date} />
    </div>
  );
};

export default Story;
