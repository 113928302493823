import { Fn } from '../../util/types';

export const keysMap: Record<string, string> = {
  Digit1: '1',
  Digit2: '2',
  Digit3: '3',
  Digit4: '4',
  Digit5: '5',
  Digit6: '6',
  Digit7: '7',
  Digit8: '8',
  Digit9: '9',
  Digit0: '0',
  Numpad1: '1',
  Numpad2: '2',
  Numpad3: '3',
  Numpad4: '4',
  Numpad5: '5',
  Numpad6: '6',
  Numpad7: '7',
  Numpad8: '8',
  Numpad9: '9',
  Numpad0: '0',
  Tab: '+/-',
  NumpadDivide: '/',
  NumpadMultiply: '*',
  NumpadSubtract: '-',
  NumpadAdd: '+',
  NumpadEnter: '=',
  Enter: '=',
  Escape: 'C',
  Period: '.',
  NumpadDecimal: '.',
  Comma: '.',
};

export const registerCalculatorKeyboardHandlers = (handler: Fn) => {
  document.addEventListener('keydown', event => {
    if (Object.keys(keysMap).includes(event.code)) {
      event.preventDefault();
      const operations = ['/', '*', '+', '=', 'C', '-'];
      const value = keysMap[event.code];
      const isOperation = operations.includes(value);
      const fakeBtnObject = {
        ...event,
        target: {
          ...event.target,
          dataset: {
            variant: !isOperation ? 'number' : 'operation',
            value,
          },
        },
      };
      handler(fakeBtnObject);
    }
  });
};

export const unregisterCalculatorKeyboardHandlers = (handler: Fn) => {
  document.removeEventListener('keydown', event => {
    if (Object.keys(keysMap).includes(event.code)) {
      handler(keysMap[event.code]);
    }
  });
};
