import React, { FC, ReactNode } from 'react';
import StoryImage from './StoryImage';

interface StoryTitleProps {
  image: string;
  children: ReactNode;
}

const StoryTitle: FC<StoryTitleProps> = ({ children, image }) => {
  return (
    <div className="story-title">
      {children}
      <StoryImage src={image} />
    </div>
  );
};

export default StoryTitle;
