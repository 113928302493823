import React, { FC } from 'react';
import StoryText from '../components/StoryText';
import StoryQuote from '../components/StoryQuote';
import StoryList from '../components/StoryList';
import StoryFooter from '../components/StoryFooter';
import { PageProps } from '../Story';
import { RelevantStories } from '../Stories';

const relevantStories: RelevantStories = [
  {
    name: 'Side Projects',
    page: 'sideProjects',
  },
  {
    name: 'Growth and Salary',
    page: 'growthAndSalary',
  },
  {
    name: 'How To Ask For Help',
    page: 'howToAskForHelp',
  },
];

const Burnout: FC<PageProps> = ({ setPage }) => {
  return (
    <>
      <StoryText>What is the "Burnout" and why it occurs? I consulted with the science.</StoryText>
      <StoryQuote>
        <StoryText>
          Burnout is a state of emotional, physical, and mental exhaustion caused by excessive and prolonged stress. It
          occurs when you feel overwhelmed, emotionally drained, and unable to meet constant demands. As the stress
          continues, you begin to lose the interest and motivation that led you to take on a certain role in the first
          place.
        </StoryText>

        <StoryText>
          Burnout reduces productivity and saps your energy, leaving you feeling increasingly helpless, hopeless,
          cynical, and resentful. Eventually, you may feel like you have nothing more to give.
        </StoryText>
      </StoryQuote>
      <StoryList title="How that we know what is it and what causes it. Let's see what can we do to prevent it!">
        <StoryText>
          <strong>Take often breaks in your working sessions.</strong> For every 30 minutes of the work, take 5 minutes
          break and get away from the screen. Take a short walk around the office/room/backyard.
        </StoryText>
        <StoryText>
          <strong>Don't forget to drink water and eat healthy.</strong> Coffee's fine as long as it's taken in
          moderation. Coffee, tea, soda and other drinks are NOT a replacement for water. Avoid eating on the working
          desk. Before you notice, you'll be overfed.
        </StoryText>
        <StoryText>
          <strong>Your eye's retina is much superior to any screen or VR device.</strong> Enjoy the outside in first
          person. Go for a walk every day. Since you'll be looking at the screen for at least several hours a day, make
          sure to get enough sleep. Your eyes will thank you.
        </StoryText>
        <StoryText>
          <strong>Think about your priorities and see if they align with your job.</strong> I would always choose a
          company with a healthy culture that values the employees than the higher paying one with bad management.
        </StoryText>
        <StoryText>
          <strong>Think about what you love to do and make it into a hobby.</strong> Be dedicated and enjoy it to the
          fullest. For example, I like playing a piano. Even though it's a sitting hobby, at least I ain't staring at
          the screen.
        </StoryText>
        <StoryText>
          <strong>Find ways to stay motivated.</strong> Learning new technologies can be really fun. Building fun side
          projects is a really great way to scratch the ich that causes the burnout and will enrich your portfolio.
          Sometimes improving the code at work, discussing the best solutions with your team and thinking about ways to
          optimise is not only fun, but could also get you a promotion.
        </StoryText>
      </StoryList>
      <StoryList title="What if you've been burned already? Break out of that infinite loop asap!">
        <StoryText>
          <strong>Learn to say NO.</strong> Decline overtime work and anything that is not within your position's scope.
        </StoryText>
        <StoryText>
          <strong>Talk to your superiors and let them know how you feel.</strong> They might have another project or
          something fun for you to do. Be advised that there are managers who don't give a flying duck about the
          employees.
        </StoryText>
        <StoryText>
          <strong>Spend more time with family and friends.</strong> Have a social activities with them, like sports,
          movies, board / card games. Have fun!
        </StoryText>
        <StoryText>
          <strong>Sometimes the best solution is to find a new job.</strong> Sometimes we just get tired and bored of
          the same project, the same people, the same environment. There's no shame in that. It's your life and your
          health at the stake.
        </StoryText>
        <StoryText>
          <strong>If you've tried most of the stuff and it doesn't work, </strong>DON'T hesitate to seek help from the
          professionals. Burnout won't go away by itself, YOU have to mend it.
        </StoryText>
      </StoryList>
      <StoryFooter setPage={setPage} relevantStories={relevantStories} />
    </>
  );
};

export default Burnout;
