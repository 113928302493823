import { MouseEventHandler, RefObject, useLayoutEffect, useState } from 'react';
import { EVENT, mpEvent } from './Analytics';

export default function useFullscreen(refEl: RefObject<HTMLElement>) {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(document.fullscreenElement !== null);

  const setFullscreen: MouseEventHandler<HTMLDivElement> = () => {
    if (refEl.current === null) return;

    refEl.current
      .requestFullscreen()
      .then(() => {
        setIsFullscreen(document.fullscreenElement !== null);
        mpEvent(EVENT.fullscreen);
      })
      .catch(() => setIsFullscreen(false));
  };

  useLayoutEffect(() => {
    document.onfullscreenchange = () => setIsFullscreen(document.fullscreenElement !== null);
    return () => {
      document.onfullscreenchange = null;
    };
  });

  return { isFullscreen, setFullscreen };
}
