import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch } from 'react-redux';
import useApps from '../appCenter';
import { downloadTxt } from '../../util/helpers';
import { tm_clearMenuItemClick } from '../../redux/taskManagerSlice';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState } from 'draft-js';
import { convert } from 'html-to-text';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { mpView } from '../../util/Analytics';

interface NotepadProps {
  fileName: string;
}

const initialState = EditorState.createEmpty();

const Notepad: FC<NotepadProps> = ({ fileName }) => {
  const [editorState, setEditorState] = useState<EditorState>(initialState);
  const dispatch = useDispatch();
  const { closeApp, menu } = useApps();
  const editorRef = useRef<HTMLElement>();

  const saveAsTxt = useCallback(() => {
    const html: string = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const text: string = convert(html, { wordwrap: 150 });
    downloadTxt(text, `${fileName}.txt`);
  }, [editorState, fileName]);

  const saveAsPdf = useCallback(() => {
    if (editorRef.current) {
      html2canvas(editorRef.current).then(canvas => {
        const imageData: string = canvas.toDataURL('image/png');
        const pdf: JsPDF = new JsPDF();
        pdf.addImage({
          imageData,
          x: 8,
          y: 8,
          height: 1056,
          width: 816,
        });
        pdf.save(`${fileName}.pdf`);
      });
    }
  }, [fileName]);

  const setEditorRef = (ref: HTMLElement) => {
    editorRef.current = ref;
  };

  useEffect(() => {
    const { notepad } = menu;
    if (notepad === 'notepad-save-text') {
      saveAsTxt();
    }

    if (notepad === 'notepad-save-pdf') {
      saveAsPdf();
    }

    if (notepad === 'notepad-new') {
      setEditorState(initialState);
    }

    if (notepad === 'notepad-exit') {
      closeApp('notepad');
    }

    if (notepad) {
      dispatch(tm_clearMenuItemClick('notepad'));
    }
  }, [menu, closeApp, saveAsTxt, saveAsPdf, dispatch]);

  useEffect(() => {
    mpView('notepad');
    editorRef.current?.focus();
  }, []);

  return (
    <Editor
      editorRef={setEditorRef}
      editorState={editorState}
      toolbarClassName="notepad-toolbar"
      wrapperClassName="notepad"
      editorClassName="notepad-editor"
      onEditorStateChange={state => {
        setEditorState(state);
        if (editorRef.current) {
          editorRef.current.scrollTop = editorRef.current.scrollHeight;
        }
      }}
    />
  );
};

export default Notepad;
