import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import Icon from '../../components/icon/Icon';
import { Avatar, Comment, Input, InputRef } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import moment from 'moment';
import CommentContent from './CommentContent';
import useFirebase from '../../util/useFirebase';
import useRTC from '../../util/useRTC';
import { CurrentPage } from './VideoCall';
import { EVENT, mpEvent } from '../../util/Analytics';

interface AvatarTheme {
  caller: CSSProperties;
  callee: CSSProperties;
}

interface Message {
  from: string;
  time: number;
  data: string;
}

const AVATAR_THEME: AvatarTheme = {
  caller: {
    backgroundColor: 'lightskyblue',
    color: 'blue',
  },
  callee: {
    backgroundColor: '#fde3cf',
    color: '#f56a00',
  },
};

interface VideosProps {
  mode: CurrentPage;
  callId: string;
  setPage: (page: CurrentPage) => void;
  username: string;
  localStream: MediaStream;
}

const Videos: FC<VideosProps> = ({ mode, callId, setPage, username, localStream }) => {
  const [roomId, setRoomId] = useState<string>(callId);
  const [callee, setCallee] = useState<string>('');
  const [caller, setCaller] = useState<string>('');
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [message, setMessage] = useState<string>('');
  const [remoteStream] = useState<MediaStream>(new MediaStream());

  const messagesRef = useRef<HTMLDivElement>(null);
  const sendMessageRef = useRef<InputRef>(null);
  const localRef = useRef<HTMLVideoElement>(null);
  const remoteRef = useRef<HTMLVideoElement>(null);

  const { getDoc, push, cleanUp, getInnerCollection } = useFirebase('calls');

  const sendMessage = async (msg: string) => {
    if (msg) {
      const callDoc = getDoc(roomId);
      const timestamp = new Date().getTime();
      const message = {
        from: username,
        data: msg,
        time: timestamp,
      };
      setMessage('');
      await push(callDoc, 'messages', message);
      mpEvent(EVENT.chatter, 'sent_message');
    }
  };

  const hangUp = async () => {
    PC.close();

    if (roomId) {
      await cleanUp(roomId, ['answerCandidates', 'offerCandidates']);
    }

    setPage(CurrentPage.home);
  };

  const { PC, onIceCandidate, getOffer, getAnswer, setRemoteDescription, handleCandidateSnapshot } = useRTC(
    localStream,
    remoteStream,
    hangUp,
  );

  const setupSources = async () => {
    if (localRef.current) {
      localRef.current.srcObject = localStream;
    }

    if (remoteRef.current) {
      remoteRef.current.srcObject = remoteStream;
    }

    if (mode === CurrentPage.create) {
      const callDoc = getDoc();
      const offerCandidates = getInnerCollection(callDoc, 'offerCandidates');
      const answerCandidates = getInnerCollection(callDoc, 'answerCandidates');

      setRoomId(callDoc.id);

      onIceCandidate(offerCandidates);

      const offer = await getOffer();

      await callDoc.set({ offer, caller: username, messages: [] });

      callDoc.onSnapshot((snapshot: any) => {
        const data = snapshot.data();
        if (data?.caller && !caller) setCaller(data.caller);
        if (data?.callee && !callee) setCallee(data.callee);
        if (!PC.currentRemoteDescription && data?.answer) {
          setRemoteDescription(data.answer);
        }

        if (data?.messages?.length !== messages?.length) {
          setMessages(data?.messages);
        }
      });

      answerCandidates.onSnapshot(handleCandidateSnapshot);
    } else if (mode === CurrentPage.join) {
      const callDoc = getDoc(callId);
      const offerCandidates = getInnerCollection(callDoc, 'offerCandidates');
      const answerCandidates = getInnerCollection(callDoc, 'answerCandidates');
      const callData = (await callDoc.get()).data();

      onIceCandidate(answerCandidates);

      await setRemoteDescription(callData?.offer);

      const answer = await getAnswer();

      await callDoc.update({ answer, callee: username });

      callDoc.onSnapshot((snapshot: any) => {
        const data = snapshot.data();
        if (data?.caller && !caller) setCaller(data.caller);
        if (data?.callee && !callee) setCallee(data.callee);
        if (data?.messages?.length !== messages.length) {
          setMessages(data?.messages);
        }
      });

      offerCandidates.onSnapshot(handleCandidateSnapshot);
    }
  };

  const handleInputPaste = (event: any) => {
    const clipboardItems = event?.clipboardData?.items;
    const items: any = [].slice.call(clipboardItems).filter((item: any) => item.type.includes('image'));

    if (items?.length) {
      const file = items[0].getAsFile();
      const reader = new FileReader();
      reader.onloadend = (event: any) => {
        const srcData = event.target.result;
        sendMessage(srcData);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    sendMessageRef?.current?.input?.addEventListener('paste', handleInputPaste);

    return () =>
      // eslint-disable-next-line
      sendMessageRef?.current?.input?.removeEventListener('paste', handleInputPaste);
    // eslint-disable-next-line
  }, [roomId]);

  useEffect(() => {
    setupSources();

    return () => {
      hangUp();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="videos">
      <div className="videos-buttons">
        <Icon
          type="PhoneOutlined"
          style={{ color: 'red', border: '1px solid red' }}
          onClick={hangUp}
          title="End Call"
        />
        <Icon
          type="CopyOutlined"
          style={{ color: 'orange', border: '1px solid orange' }}
          onClick={() => {
            navigator.clipboard.writeText(roomId);
          }}
          title="Copy Room ID"
        />
      </div>
      <div className="streams">
        <div className="stream-owner">
          <span className="owner">{username}</span>
          <video ref={localRef} autoPlay playsInline className="local" muted />
        </div>
        <div className="stream-callee">
          {username === callee && !!caller ? (
            <span className="callee">{caller}</span>
          ) : callee ? (
            <span className="callee">{callee}</span>
          ) : null}
          <video ref={remoteRef} autoPlay playsInline className="remote" />
        </div>
      </div>
      <div className="chat">
        <div className="messages" ref={messagesRef}>
          {messages?.map((item: Message, index: number) => {
            return (
              <Comment
                key={`${item.time}-${index}`}
                author={<span>{item.from}</span>}
                avatar={
                  <Avatar
                    alt="avatar"
                    style={item.from === caller ? { ...AVATAR_THEME.caller } : { ...AVATAR_THEME.callee }}
                  >
                    {item.from
                      .split(' ')
                      .map((s: string) => s[0])
                      .join('')}
                  </Avatar>
                }
                content={<CommentContent text={item.data} />}
                datetime={<span>{moment(item.time).format('LT')}</span>}
              />
            );
          })}
        </div>
        <Input
          ref={sendMessageRef}
          placeholder="Send message..."
          value={message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
          onPressEnter={() => sendMessage(message)}
          addonAfter={<SendOutlined onClick={() => sendMessage(message)} />}
        />
      </div>
    </div>
  );
};

export default Videos;
