import React, { FC, useEffect, useState } from 'react';
import VideoCallMenu from './VideoCallMenu';
import Videos from './Videos';
import { message } from 'antd';
import { mpView } from '../../util/Analytics';

export enum CurrentPage {
  create = 'create',
  join = 'join',
  home = 'home',
}

const VideoCall: FC = () => {
  const [currentPage, setCurrentPage] = useState<CurrentPage>(CurrentPage.home);
  const [joinCode, setJoinCode] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [localStream, setLocalStream] = useState<MediaStream>(new MediaStream());

  useEffect(() => {
    const getMediaDevices = async () => {
      try {
        const _localStream: MediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setLocalStream(_localStream);
      } catch (error) {
        message.error('Your camera or microphone is turned off');
        setLocalStream(new MediaStream());
      }
    };

    getMediaDevices();

    mpView('chatter');

    navigator.mediaDevices.addEventListener('devicechange', getMediaDevices);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getMediaDevices);
    };
  }, []);

  return currentPage === CurrentPage.home ? (
    <VideoCallMenu
      joinCode={joinCode}
      setJoinCode={setJoinCode}
      setPage={setCurrentPage}
      username={username}
      setUsername={setUsername}
      localStream={localStream}
    />
  ) : (
    <Videos
      mode={currentPage}
      callId={joinCode}
      setPage={(page: CurrentPage) => {
        setCurrentPage(page);
        setJoinCode('');
      }}
      username={username}
      localStream={localStream}
    />
  );
};

export default VideoCall;
