import React, { FC } from 'react';
import Icon from '../icon/Icon';
import { EVENT, mpEvent } from '../../util/Analytics';

const Contact: FC = () => {
  const openGoogleMaps = () => {
    mpEvent(EVENT.outbound_link, 'google_maps');
    window.open(
      'https://www.google.com/maps/place/Husinskih+rudara+24,+Beograd/@44.8093238,20.5201557,17.25z/data=!4m5!3m4!1s0x475a7a66518e4d0f:0xd503378dd26719d0!8m2!3d44.8102796!4d20.5192564',
    );
  };

  const callMe = () => {
    mpEvent(EVENT.outbound_link, 'phone');
    window.open('tel:+381611825585', '_self');
  };

  const mailMe = () => {
    mpEvent(EVENT.outbound_link, 'mail');
    window.open('mailto:nbojanovic.dev@gmail.com', '_self');
  };

  const openSocialMediaPage = (sm: string) => {
    let media = 'https://www.facebook.com/nikola.bojanovic.39';
    if (sm === 'linkedin') {
      mpEvent(EVENT.outbound_link, 'linked_in');
      media = 'https://www.linkedin.com/in/nikola-bojanovic-972407152/';
    }
    if (sm === 'github') {
      mpEvent(EVENT.outbound_link, 'git_hub');
      media = 'https://github.com/NicolasBG87';
    }
    window.open(media, '_blank');
  };

  return (
    <div className="widget-contact">
      <p>If you're calling on the cell-phone, please do so Monday to Friday 9AM - 17PM (GMT+1)</p>
      <div className="contact-item">
        <Icon type="custom_map" onClick={openGoogleMaps} />
        <span>Husinskih Rudara 24 Belgrade, Serbia</span>
      </div>

      <div className="contact-item">
        <Icon type="custom_phone" onClick={callMe} />
        <span>+381(61)182-5585</span>
      </div>

      <div className="contact-item">
        <Icon type="custom_mail" onClick={mailMe} />
        <span>nbojanovic.dev@gmail.com</span>
      </div>

      <div className="contact-socialMedia">
        <Icon type="FacebookFilled" onClick={() => openSocialMediaPage('facebook')} />
        <Icon type="LinkedinFilled" onClick={() => openSocialMediaPage('linkedin')} />
        <Icon type="GithubFilled" onClick={() => openSocialMediaPage('github')} />
      </div>
    </div>
  );
};

export default Contact;
