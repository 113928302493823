interface IceServers {
  iceServers: Array<{
    urls: Array<string>;
  }>;
}

const SERVERS: IceServers = {
  iceServers: [
    {
      urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
    },
  ],
};

const useRTC = (localStream: MediaStream, remoteStream: MediaStream, closeCallback: () => void) => {
  const PC: RTCPeerConnection = new RTCPeerConnection(SERVERS);

  PC.ontrack = (event: RTCTrackEvent) => {
    event.streams[0].getTracks().forEach((track: MediaStreamTrack) => {
      remoteStream.addTrack(track);
    });
  };

  PC.onconnectionstatechange = ({ target }: any) => {
    if (target.connectionState === 'disconnected') {
      closeCallback();
    }
  };

  localStream.getTracks().forEach((track: MediaStreamTrack) => {
    PC.addTrack(track, localStream);
  });

  const onIceCandidate = (candidates: any) => {
    PC.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      if (event.candidate) {
        candidates.add(event.candidate.toJSON());
      }
    };
  };

  const getOffer = async () => {
    const offerDescription: RTCSessionDescriptionInit = await PC.createOffer();
    await PC.setLocalDescription(offerDescription);

    return {
      sdp: offerDescription.sdp,
      type: offerDescription.type,
    };
  };

  const getAnswer = async () => {
    const answerDescription: RTCSessionDescriptionInit = await PC.createAnswer();
    await PC.setLocalDescription(answerDescription);

    return {
      sdp: answerDescription.sdp,
      type: answerDescription.type,
    };
  };

  const setRemoteDescription = async (desc: RTCSessionDescription) => {
    const answerDescription: RTCSessionDescriptionInit = new RTCSessionDescription(desc);
    await PC.setRemoteDescription(answerDescription);
  };

  const addIceCandidate = (data: RTCIceCandidateInit) => PC.addIceCandidate(new RTCIceCandidate(data));

  const handleCandidateSnapshot = (snapshot: any) => {
    snapshot.docChanges().forEach((change: any) => {
      if (change.type === 'added') {
        addIceCandidate(change.doc.data());
      }

      if (change.type === 'removed') {
        closeCallback();
      }
    });
  };

  return {
    PC,
    onIceCandidate,
    getOffer,
    getAnswer,
    setRemoteDescription,
    handleCandidateSnapshot,
  };
};

export default useRTC;
