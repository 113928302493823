import { BoardType, GameBoard, Piece } from './types';

export const gameBoard = (_position: BoardType = Array(9).fill(null), _turn: Piece = Piece.x): GameBoard => {
  const turn = _turn;
  const position = _position;

  const oppositeTurn = () => (turn === Piece.x ? Piece.o : Piece.x);

  const move = (location: number) => {
    const tempPosition = position.slice();
    tempPosition[location] = turn;

    return gameBoard(tempPosition, oppositeTurn());
  };

  const legalMoves = () => {
    const moves: Array<number> = [];

    position.forEach((item, index) => {
      if (!item) moves.push(index);
    });

    return moves;
  };

  const isWin = () => {
    return !!(
      (
        (position[0] && position[0] === position[1] && position[0] === position[2]) || // row 0
        (position[3] && position[3] === position[4] && position[3] === position[5]) || // row 1
        (position[6] && position[6] === position[7] && position[6] === position[8]) || // row 2
        (position[0] && position[0] === position[3] && position[0] === position[6]) || // col 0
        (position[1] && position[1] === position[4] && position[1] === position[7]) || // col 1
        (position[2] && position[2] === position[5] && position[2] === position[8]) || // col 2
        (position[0] && position[0] === position[4] && position[0] === position[8]) || // diag 0
        (position[2] && position[2] === position[4] && position[2] === position[6])
      ) // diag 1
    );
  };

  const isDraw = () => !isWin() && !legalMoves().length;

  return {
    isWin,
    isDraw,
    oppositeTurn,
    legalMoves,
    move,
    turn,
  };
};

const minmax = (board: GameBoard, maximizing: boolean, originalPlayer: Piece) => {
  if (board.isWin() && originalPlayer === board.oppositeTurn()) {
    return 1;
  } else if (board.isWin() && originalPlayer !== board.oppositeTurn()) {
    return -1;
  } else if (board.isDraw()) {
    return 0;
  }

  if (maximizing) {
    let bestEval: number = Number.MIN_SAFE_INTEGER;

    for (const move of board.legalMoves()) {
      const result: number = minmax(board.move(move), false, originalPlayer);
      bestEval = Math.max(result, bestEval);
    }

    return bestEval;
  } else {
    let worstEval: number = Number.MAX_SAFE_INTEGER;

    for (const move of board.legalMoves()) {
      const result: number = minmax(board.move(move), true, originalPlayer);
      worstEval = Math.min(result, worstEval);
    }

    return worstEval;
  }
};

export const findBestMove = (board: GameBoard) => {
  let bestEval: number = Number.MIN_SAFE_INTEGER;
  let bestMove = -1;

  board.legalMoves().forEach((move: number) => {
    const result: number = minmax(board.move(move), false, board.turn);

    if (result > bestEval) {
      bestEval = result;
      bestMove = move;
    }
  });

  return bestMove;
};
