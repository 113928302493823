import React, { FC } from 'react';
import Icon from '../icon/Icon';
import { useDispatch } from 'react-redux';
import { ac_turnOn } from '../../redux/appControlSlice';

const TurnedOff: FC = () => {
  const dispatch = useDispatch();

  const turnOn = () => {
    dispatch(ac_turnOn());
  };
  return (
    <div className="appLoading">
      <Icon type="PoweroffOutlined" style={{ fontSize: '6rem' }} className="turn-on" onClick={turnOn} />
    </div>
  );
};

export default TurnedOff;
