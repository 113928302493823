import React, { FC, useEffect, useState } from 'react';
import useApps, { AppProps } from '../../apps/appCenter';
import { Divider, Empty } from 'antd';
import Icon from '../icon/Icon';
import AppInfo from '../appInfo/AppInfo';

interface SearchResultProps {
  term: string;
}

const SearchResult: FC<SearchResultProps> = ({ term }) => {
  const [filtered, setFiltered] = useState<Array<AppProps>>([]);
  const [current, setCurrent] = useState<AppProps | null>(null);
  const { apps, openApp } = useApps();

  useEffect(() => {
    setFiltered(apps.filter(app => app.appName.toLowerCase().startsWith(term.toLowerCase())));
  }, [term, apps]);

  return (
    <div className="search-result-wrapper">
      <p>Searching for: {term}</p>
      <Divider />
      {!filtered.length ? (
        <Empty description={`No apps with the name "${term}" found`} />
      ) : (
        <div className="search-result">
          <div className="search-info">
            {filtered.map((app: AppProps) => {
              return (
                <div
                  className="search-info-item"
                  onMouseEnter={() => setCurrent(app)}
                  onMouseLeave={() => setCurrent(null)}
                  onClick={() => openApp(app.appID)}
                  key={app.appID}
                >
                  <Icon type={app.icon} />
                  {app.appName}
                </div>
              );
            })}
          </div>
          {current && <AppInfo appID={current.appID} />}
        </div>
      )}
    </div>
  );
};

export default SearchResult;
