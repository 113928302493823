import { FC } from 'react';

import BurnoutImage from './assets/images/burnout.png';
import HowNotToHelpImage from './assets/images/howNotToHelp.png';
import GivingFeedbackImage from './assets/images/givingFeedback.png';
import SideProjectsImage from './assets/images/sideProjects.png';
// import PrettyPrettierImage from './assets/images/prettyPrettier.png';
// import CodeGeneratorsImage from './assets/images/codeGenerators.png';
// import DontLearnLanguagesImage from './assets/images/dontLearnLanguages.png';
// import HowToAskForHelpImage from './assets/images/howToAskForHelp.png';
// import LeadingMeetingsImage from './assets/images/leadingMeetings.png';
// import IgnoreHTMLandCSSImage from './assets/images/ignoreHTMLandCSS.png';
// import IdeHacksImage from './assets/images/ideHacks.png';
// import GrowthAndSalaryImage from './assets/images/growthAndSalary.png';
// import BeforeYouStartCodingImage from './assets/images/beforeYouStartCoding.png';
// import TaskEstimationImage from './assets/images/taskEstimation.png';
// import DocumentYourCodeImage from './assets/images/documentYourCode.png';
// import DrawDiagramsImage from './assets/images/drawDiagrams.png';

import Burnout from './pages/Burnout';
import GivingFeedback from './pages/GivingFeedback';
import HowNotToHelp from './pages/HowNotToHelp';
import SideProjects from './pages/SideProjects';
// import PrettyPrettier from './pages/PrettyPrettier';
// import CodeGenerators from './pages/CodeGenerators';
// import DontLearnLanguages from './pages/DontLearnLanguages';
// import HowToAskForHelp from './pages/HowToAskForHelp';
// import LeadingMeetings from './pages/LeadingMeetings';
// import IgnoreHTMLandCSS from './pages/IgnoreHTMLandCSS';
// import IdeHacks from './pages/IdeHacks';
// import GrowthAndSalary from './pages/GrowthAndSalary';
// import BeforeYouStartCoding from './pages/BeforeYouStartCoding';
// import TaskEstimation from './pages/TaskEstimation';
// import DocumentYourCode from './pages/DocumentYourCode';
// import DrawDiagrams from './pages/DrawDiagrams';

export interface StoryType {
  id: string;
  label: string;
  image: string;
  date: string;
  component: FC<any>;
}

const STORIES: Array<StoryType> = [
  {
    id: 'burnout',
    label: 'Burnout',
    image: BurnoutImage,
    date: '2021-04-24',
    component: Burnout,
  },
  {
    id: 'howNotToHelp',
    label: 'How NOT To Help',
    image: HowNotToHelpImage,
    date: '2021-05-16',
    component: HowNotToHelp,
  },
  {
    id: 'givingFeedback',
    label: 'Giving Feedback',
    image: GivingFeedbackImage,
    date: '2021-06-06',
    component: GivingFeedback,
  },
  {
    id: 'sideProjects',
    label: 'Side Projects',
    image: SideProjectsImage,
    date: '2021-07-17',
    component: SideProjects,
    // }, {
    //     id: 'prettyPrettier',
    //     label: 'Pretty Prettier',
    //     image: PrettyPrettierImage,
    //     date: '2021-08-10',
    //     component: PrettyPrettier
    // }, {
    //     id: 'codeGenerators',
    //     label: 'Code Generators',
    //     image: CodeGeneratorsImage,
    //     date: '2021-09-01',
    //     component: CodeGenerators
    // }, {
    //     id: 'dontLearnLanguages',
    //     label: 'Don\'t Learn Languages',
    //     image: DontLearnLanguagesImage,
    //     date: '2021-10-14',
    //     component: DontLearnLanguages
    // }, {
    //     id: 'howToAskForHelp',
    //     label: 'How To Ask For Help',
    //     image: HowToAskForHelpImage,
    //     date: '2021-11-16',
    //     component: HowToAskForHelp
    // }, {
    //     id: 'leadingMeetings',
    //     label: 'Leading Meetings',
    //     image: LeadingMeetingsImage,
    //     date: '2021-12-27',
    //     component: LeadingMeetings
    // }, {
    //     id: 'ignoreHTMLandCSS',
    //     label: 'Ignore HTML and CSS',
    //     image: IgnoreHTMLandCSSImage,
    //     date: '2022-01-04',
    //     component: IgnoreHTMLandCSS
    // }, {
    //     id: 'ideHacks',
    //     label: 'Ide Hacks',
    //     image: IdeHacksImage,
    //     date: '2022-02-14',
    //     component: IdeHacks
    // }, {
    //     id: 'growthAndSalary',
    //     label: 'Growth and Salary',
    //     image: GrowthAndSalaryImage,
    //     date: '2022-03-27',
    //     component: GrowthAndSalary
    // }, {
    //     id: 'beforeYouStartCoding',
    //     label: 'Before You Start Coding',
    //     image: BeforeYouStartCodingImage,
    //     date: '2022-04-19',
    //     component: BeforeYouStartCoding
    // }, {
    //     id: 'taskEstimation',
    //     label: 'Task Estimation',
    //     image: TaskEstimationImage,
    //     date: '2022-05-11',
    //     component: TaskEstimation
    // }, {
    //     id: 'documentYourCode',
    //     label: 'Document Your Code',
    //     image: DocumentYourCodeImage,
    //     date: '2022-06-03',
    //     component: DocumentYourCode
    // }, {
    //     id: 'drawDiagrams',
    //     label: 'Draw Diagrams',
    //     image: DrawDiagramsImage,
    //     date: '2022-07-05',
    //     component: DrawDiagrams
  },
].reverse();

export default STORIES;
