import { FC } from 'react';

import Experience from '../components/widgets/Experience';
import Skills from '../components/widgets/Skills';
import AboutMe from '../components/widgets/AboutMe';
import Contact from '../components/widgets/Contact';

export interface Widget {
  name: string;
  id: string;
  icon: string;
  component: FC;
}

export const WIDGETS: Array<Widget> = [
  {
    name: 'About Me',
    id: 'widget_aboutMe',
    icon: 'AliwangwangFilled',
    component: AboutMe,
  },
  {
    name: 'Skills',
    id: 'widget_skills',
    icon: 'FireFilled',
    component: Skills,
  },
  {
    name: 'Contact',
    id: 'widget_contact',
    icon: 'ScheduleFilled',
    component: Contact,
  },
  {
    name: 'Experience',
    id: 'widget_experience',
    icon: 'ExperimentFilled',
    component: Experience,
  },
];
