import React, { FC } from 'react';
import useApps, { AppCategory } from '../../apps/appCenter';
import Icon from '../icon/Icon';

interface AppInfoProps {
  appID: string;
}

const AppInfo: FC<AppInfoProps> = ({ appID }) => {
  const { getAppDetails } = useApps();
  const { icon, appName, category, appInfo, tutorial } = getAppDetails(appID);

  return (
    <div className="app-info">
      <Icon type={icon} className="app-info-icon" />
      <h1>{appName}</h1>
      <small>{AppCategory[category]}</small>
      <h3>{appInfo}</h3>
      {tutorial && (
        <>
          <h2>Details:</h2>
          <ul>
            {tutorial.map((item: string, index: number) => (
              <li key={`app-info-${appID}-${index}`}>{item}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default AppInfo;
