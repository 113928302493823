import { AppsProps } from './appCenter';

import Notepad from './notepad/Notepad';
import VideoCall from './videoCall/VideoCall';
import Calculator from './calculator/Calculator';
import CodeSandbox from './codesandbox/CodeSandbox';
import TicTacToe from './ticTacToe/TicTacToe';
import Stories from './stories/Stories';

export const APPS: AppsProps = [
  {
    appID: 'calculator',
    appName: 'Calculator',
    component: Calculator,
    icon: 'custom_calculator',
    maximizable: false,
    width: '70rem',
    taskBar: true,
    editableTitle: false,
    toolbar: undefined,
    category: 'app',
    appInfo: 'Perform standard math operations. Has keyboard support.',
    tutorial: [
      'Use number keys (numpad included) to set operand',
      'Use / * - + , . as operations',
      'Enter key will do the calculation',
      'Escape key will clear calculation',
      'Tab will change sign (+/-)',
    ],
  },
  {
    appID: 'codesandbox',
    appName: 'Code Editor',
    component: CodeSandbox,
    icon: 'CodeSandboxOutlined',
    maximizable: true,
    width: '100rem',
    taskBar: true,
    editableTitle: false,
    toolbar: undefined,
    category: 'app',
    appInfo: 'Embedded codesandbox.io into an iFrame for quick access. Serves as live React code editor.',
    tutorial: ['Refer to official codesandbox.io documentation'],
  },
  {
    appID: 'notepad',
    appName: 'Notepad',
    component: Notepad,
    icon: 'custom_notepad',
    maximizable: true,
    width: '90rem',
    taskBar: true,
    editableTitle: true,
    toolbar: [
      {
        key: 'notepad-new',
        label: 'New',
      },
      {
        key: 'notepad-save-text',
        label: 'Save as Text',
      },
      {
        key: 'notepad-save-pdf',
        label: 'Save as PDF',
      },
      {
        key: 'notepad-exit',
        label: 'Exit',
      },
    ],
    category: 'app',
    appInfo: 'Rich text editor editor application. Supports exporting as plain text and PDF.',
    tutorial: [
      'Change text color, size and decoration',
      'Change alignments and formatting',
      'Create ordered and unordered lists',
      'Use hyperlinks',
      'Import images and emojis',
      'Embed from a url',
      'Export as .txt',
      'Export as .pdf',
    ],
  },
  {
    appID: 'ticTacToe',
    appName: 'TicTacToe',
    component: TicTacToe,
    icon: 'custom_ticTacToe',
    maximizable: false,
    width: '80rem',
    taskBar: false,
    editableTitle: false,
    toolbar: undefined,
    category: 'game',
    appInfo:
      'My version of a popular game. Used minmax probability algorithm for the AI... it\'s pretty damn tough you know ;)',
    tutorial: [
      'You can mute the background music using the icon in the top right corner',
      'Choose your piece and try to beat the monster AI',
      'Pay attention to the timer, if it runs out your move will be played automatically',
      'The game has no end. It will keep restarting until you give up on trying to beat the AI :D',
    ],
  },
  {
    appID: 'videoCall',
    appName: 'Chatter',
    component: VideoCall,
    icon: 'custom_videocall',
    maximizable: true,
    width: '80rem',
    taskBar: false,
    editableTitle: false,
    toolbar: undefined,
    category: 'app',
    appInfo: '1-on-1 video call with text chat',
    tutorial: [
      'Allows max 2 users',
      'Voice chat with camera',
      'Text chat with url parser',
      'Youtube video links',
      'Gif links',
      'Paste image directly into the chat',
      'No media server, uses WebRTC and Firebase for communication',
    ],
  },
  {
    appID: 'stories',
    appName: 'My Stories',
    component: Stories,
    icon: 'custom_stories',
    maximizable: false,
    width: '93.3rem',
    taskBar: true,
    editableTitle: false,
    toolbar: undefined,
    category: 'app',
    appInfo: 'Blog like stories',
    tutorial: ['Hope you enjoy reading as much as I did writing it'],
  },
];
