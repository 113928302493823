import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ttt_createNewGame, ttt_resetGameState } from '../../redux/ticTacToeSlice';

import { ReloadOutlined } from '@ant-design/icons/lib';
import { Button } from 'antd';

import PieceChooser from './PieceChooser';
import Board from './Board';
import Icon from '../../components/icon/Icon';
import { useAudio } from '../../util/useAudio';
import backgroundSoundRaw from './sounds/background.mp3';
import { mpView } from '../../util/Analytics';
import { RootStore } from '../../redux/store';
import { Piece } from './types';

const TicTacToe: FC = () => {
  const { isGameEnd, oWinCounter, xWinCounter, drawCounter, player } = useSelector(
    (state: RootStore) => state.ticTacToe,
  );
  const [isMusicPlaying, setIsMusicPlaying] = useState<boolean>(true);
  const dispatch = useDispatch();
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const { audio } = useAudio(backgroundSoundRaw);

  const resetGame = () => {
    if (!isGameEnd) {
      dispatch(ttt_resetGameState());
    } else {
      clearTimeout(timeout.current);
      dispatch(ttt_createNewGame());
    }
  };

  const handleGameEnd = () => {
    timeout.current = setTimeout(() => dispatch(ttt_createNewGame()), 2000);
  };

  const toggleMusic = () => setIsMusicPlaying(p => !p);

  useEffect(() => {
    mpView('tic_tac_toe');
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      dispatch(ttt_resetGameState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isMusicPlaying) {
      audio.loop = true;
      audio.volume = 0.4;
      audio.play();
    } else {
      audio.pause();
    }

    return () => audio.pause();
  }, [isMusicPlaying, audio]);

  return (
    <div className="ttt">
      <Icon
        className="music-toggle"
        type="SoundFilled"
        style={{ color: isMusicPlaying ? 'green' : 'red' }}
        onClick={toggleMusic}
      />
      {!player ? (
        <PieceChooser />
      ) : (
        <>
          <div className="status">
            <p className="status-counter">
              <label>Wins:</label> {player === Piece.x ? xWinCounter : oWinCounter}
            </p>
            <p className="status-counter">
              <label>Loses:</label> {player === Piece.x ? oWinCounter : xWinCounter}
            </p>
            <p className="status-counter">
              <label>Draws:</label> <span>{drawCounter}</span>
            </p>
          </div>
          <Board onGameEnd={handleGameEnd} />
          <Button icon={<ReloadOutlined />} size="large" onClick={resetGame}>
            Restart
          </Button>
        </>
      )}
    </div>
  );
};

export default TicTacToe;
