import mixpanel from 'mixpanel-browser';
import { isDev } from './helpers';

export enum EVENT {
  fullscreen = 'fullscreen',
  outbound_link = 'outbound_link',
  chatter = 'chatter',
  view_story = 'view_story',
  download = 'download',
}

export const initAnalytics = () => {
  !isDev() && mixpanel.init('590ffe934e6e3cf77f92ceff166096e2', { debug: false });
};

export const mpEvent = (event: string, action?: string) => {
  !isDev() && mixpanel.track(event, { action });
};

export const mpView = (page: string) => {
  !isDev() && mpEvent('view_page', page);
};
