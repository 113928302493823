import { useDispatch, useSelector } from 'react-redux';
import { tm_pop, tm_push, tm_removeActive, tm_setActive } from '../redux/taskManagerSlice';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { APPS } from './appList';
import { WIDGETS } from './widgetList';
import { RootStore } from '../redux/store';

export type Toolbar = Array<ItemType> | undefined;

export type AppProps = {
  appID: string;
  appName: string;
  component: any;
  icon: string;
  maximizable: boolean;
  width: number | string;
  taskBar: boolean;
  editableTitle: boolean;
  toolbar: Toolbar;
  category: keyof typeof AppCategory;
  appInfo?: string;
  tutorial?: Array<string>;
};

export type AppsProps = Array<AppProps>;

export enum AppCategory {
  app = 'Application',
  game = 'Game',
}

const useApps = () => {
  const { running, menu, active } = useSelector((state: RootStore) => state.taskManager);
  const dispatch = useDispatch();

  const openApp = (appID: string) => {
    dispatch(tm_push(appID));
    setActive(appID);
  };

  const closeApp = (appID: string) => {
    dispatch(tm_pop(appID));
    const anotherApp = running.find((item: string) => item !== appID);
    if (anotherApp) {
      setActive(anotherApp);
    } else {
      dispatch(tm_removeActive());
    }
  };

  const isRunning = (appID: string) => {
    return running.includes(appID);
  };

  const isActive = (appID: string) => {
    return appID === active;
  };

  const toggleApp = (appID: string) => {
    if (isRunning(appID)) {
      setActive(appID);
    } else {
      openApp(appID);
    }
  };

  const getAppDetails = (appID: string) => {
    return APPS.find(item => item.appID === appID) || APPS[0];
  };

  const setActive = (appID: string) => {
    dispatch(tm_setActive(appID));
  };

  const openAppInfo = (appID: string) => {
    openApp('appInfo_' + appID);
  };

  return {
    openApp,
    closeApp,
    apps: APPS,
    widgets: WIDGETS,
    running,
    menu,
    isActive,
    isRunning,
    toggleApp,
    setActive,
    openAppInfo,
    getAppDetails,
  };
};

export default useApps;
