import React, { FC, useEffect } from 'react';
import { mpView } from '../../util/Analytics';

const CodeSandbox: FC = () => {
  useEffect(() => {
    mpView('code_editor');
  }, []);

  return (
    <iframe
      title="codesandbox.io"
      src="https://codesandbox.io/embed/new?codemirror=1"
      style={{
        width: '100%',
        height: '100%',
        minHeight: '70rem',
        border: 0,
        borderRadius: '0.4rem',
        overflow: 'hidden',
      }}
      allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
      sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    />
  );
};

export default CodeSandbox;
