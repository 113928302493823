import React, { FC } from 'react';
import StoryFooter from '../components/StoryFooter';
import StoryText from '../components/StoryText';
import StoryImage from '../components/StoryImage';

import PieceOfCakeImage from '../assets/images/pieceOfCake.png';
import { Avatar, Comment } from 'antd';
import StoryQuote from '../components/StoryQuote';
import { PageProps } from '../Story';
import { RelevantStories } from '../Stories';

const relevantStories: RelevantStories = [
  {
    name: 'Giving Feedback',
    page: 'givingFeedback',
  },
  {
    name: 'How To Ask For Help',
    page: 'howToAskForHelp',
  },
  {
    name: 'Leading Meetings',
    page: 'leadingMeetings',
  },
];

const HowNotToHelp: FC<PageProps> = ({ setPage }) => {
  return (
    <>
      <StoryText>
        Hey there, Senior engineer. Life's good, huh? You're probably mentoring between 5 and 10 junior and intermediate
        engineers. Are they pinging you several times a day each? Do they ask for your opinion before each commit? Are
        they completely lost when you're on a vacation or take a sick leave?
      </StoryText>
      <StoryText className="story-text_underline">In most cases it's not them... it's YOU!</StoryText>
      <StoryText>
        We all had to start somewhere. In that very beginning, the most important thing to learn is not the technology,
        the product, the best practices nor how to reverse a binary tree... The most important thing to learn is how to
        learn! Once we understand that, we'll know how to teach.
      </StoryText>
      <StoryImage src={PieceOfCakeImage} />
      <StoryText>Piece of cake, right?... Well, yes and no!</StoryText>
      <StoryText>
        When I landed my first programming job I was very insecure. I didn't know the product at all and the code base
        was huge. I knew how to code, but was it performant and easy to read? Nope! It was a big pile of mess! I felt
        such a strong pressure to prove myself worthy of the chance I've been given. The{' '}
        <a href="https://en.wikipedia.org/wiki/Impostor_syndrome" target="_blank" rel="noreferrer">
          imposter syndrome
        </a>{' '}
        has kicked in from the very start! Luckily, I had a great mentors (thanks Techo & Joco) who knew how to put me
        back on track.
      </StoryText>
      <StoryText className="story-text_underline">
        Take some time to remember how was your start in the first job.
      </StoryText>
      <StoryText>Imagine there's a Senior named Senior and a Junior named Junior (very creative, I know).</StoryText>
      <StoryQuote>
        <Comment
          content={<p>Hey man! I don't know where to start with this task. Could you help me out?</p>}
          author="Junior"
          avatar={<Avatar>J</Avatar>}
        >
          <Comment
            content={
              <p>
                Sure. Use X architecture, Y and Z components. There's a B service that you'll have to map like this: ...
              </p>
            }
            author="Senior"
            avatar={<Avatar>S</Avatar>}
          />
        </Comment>
      </StoryQuote>
      <StoryText>
        This method will yield the fastest short term results. A Junior will do as you say without blinking and will
        code-monkey the feature quickly. But this limits the Juniors in the long run. They will tend to rely on you all
        the time and will feel even more insecure and scared to act independently.
      </StoryText>
      <StoryQuote>
        <Comment
          content={<p>Hey man! I don't know where to start with this task. Could you help me out?</p>}
          author="Junior"
          avatar={<Avatar>J</Avatar>}
        >
          <Comment
            content={
              <p>
                Sure. I want you to think about the architecture first. What would make the most sense in this project?
                Check out the components we already have and see if we can fit some to avoid code duplication. We
                probably already have a service you need that might require re-mapping.
              </p>
            }
            author="Senior"
            avatar={<Avatar>S</Avatar>}
          />
        </Comment>
      </StoryQuote>
      <StoryText>
        Now you're encouraging research and thinking. Probably the two most important programmer feats. You gave just
        enough information to know where to begin, without spoiling the fun part. Of course, if still struggling you can
        show practical examples or event pair program it. And, without knowing it, you subconsciously send message that
        everything is okay and that there's plenty of time. What you're saying is that it's OKAY to make mistakes. Your
        Junior will not feel pressured and will absorb information like crazy.
      </StoryText>
      <StoryText className="story-text_underline">
        What's your mentoring approach?
        <br />
      </StoryText>
      <StoryFooter setPage={setPage} relevantStories={relevantStories} />
    </>
  );
};

export default HowNotToHelp;
