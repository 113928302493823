import React, { FC } from 'react';
import StoryText from '../components/StoryText';
import StoryList from '../components/StoryList';
import StoryFooter from '../components/StoryFooter';
import { PageProps } from '../Story';
import StoryQuote from '../components/StoryQuote';
import { RelevantStories } from '../Stories';

const relevantStories: RelevantStories = [
  {
    name: 'How Not To Help',
    page: 'howNotToHelp',
  },
  {
    name: 'Growth and Salary',
    page: 'growthAndSalary',
  },
  {
    name: 'How To Ask For Help',
    page: 'howToAskForHelp',
  },
];

const GivingFeedback: FC<PageProps> = ({ setPage }) => {
  return (
    <>
      <StoryQuote>
        <StoryText>
          Feedback is a tool to help us and others improve. If you use this mindset and some common sense, constructive
          feedback will become one of the most powerful and empowering additions to your soft skills toolbox.
        </StoryText>
      </StoryQuote>
      <StoryText>
        This topic is rather large and could easily be a book. I'll summarize my personal opinion on how it could be
        done and what worked for me. This is definitely not something you should blindly follow. It heavily depends on
        the company culture and how the competencies are structured.
      </StoryText>
      <StoryText className="story-text_underline">
        Let's make something clear! This is not a God given gift. This is a learnable skill that gets better the more
        you practice it.
      </StoryText>
      <StoryList title="I try to follow these guidelines:">
        <StoryText>
          <strong>This is an ongoing process.</strong> You have to be involved and connected with what the employee is
          doing. A yearly performance review is not enough.
        </StoryText>
        <StoryText>
          <strong>Give feedback often.</strong> This will not only allow you to connect more, but will also prevent some
          mistakes early on. How could you expect a person to fix the mistakes if they don't know they're doing
          something wrong in the first place. Remember, feedback should not only be given when bad things happen. Use it
          to acknowledge someone's work and motivate them to grow.
        </StoryText>
        <StoryText>
          <strong>Be constructive.</strong> If you really took time and evaluated the employees work, you'll know the
          exact examples. Be specific about ways to improve and grow.
        </StoryText>
        <StoryText>
          <strong>Offer guidance.</strong> Sometimes all it takes is a one short mentoring call. Use all the experience
          and knowledge you have to guide others.
        </StoryText>
        <StoryText>
          <strong>Practice empathy.</strong> If you notice a sudden drop in performance, don't jump into conclusions
          yourself and play the blame game. Ask how are they doing? See if there's something YOU can do to help them.
          Don't treat your employees as a sponge to squeeze. They are human beings with thoughts, emotions and problems.
        </StoryText>
        <StoryText>
          <strong>Be unbiased.</strong> It's in our nature to be more forgiving towards the people we like. Nothing
          stops you from befriending a teammate. On a contrary, it's very productive and greatly helps building trust.
          But you have to separate emotions from facts.
        </StoryText>
        <StoryText>
          <strong>Ask for a feedback.</strong> Look at this as a bi-directional road. Encourage teammates to give clear
          and honest feedback on your work. Since this is a learnable skill, learn... learn from the feedback. Ask
          questions on what could you change to bring out the natural best from them.
        </StoryText>
      </StoryList>
      <StoryText className="story-text_underline">
        What was your experience with giving and receiving feedbacks so far? I'd really love to hear your story.
      </StoryText>
      <StoryFooter setPage={setPage} relevantStories={relevantStories} />
    </>
  );
};

export default GivingFeedback;
