import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Divider } from 'antd';
import Icon from '../../components/icon/Icon';
import Story from './Story';
import Home from './pages/Home';
import STORIES, { StoryType } from './storyList';
import { mpView } from '../../util/Analytics';

export type RelevantStories = Array<{
  name: string;
  page: string;
}>;

const Stories = () => {
  const [page, setPage] = useState<string>('home');

  const goHome = () => setPage('home');

  const currentStory: StoryType | undefined = useMemo(() => {
    return STORIES.find(story => story.id === page);
  }, [page]);

  useEffect(() => {
    mpView('stories');
  }, []);

  return (
    <div className="stories">
      <div className="stories-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <span onClick={goHome}>Stories</span>
          </Breadcrumb.Item>
          {currentStory && (
            <Breadcrumb.Item>
              <span className="stories-breadcrumb">{currentStory.label}</span>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>

        {page !== 'home' && <Icon className="stories-header_icon" type="LeftOutlined" onClick={goHome} />}
      </div>
      <Divider />
      {!currentStory ? <Home setPage={setPage} /> : <Story story={currentStory} setPage={setPage} />}
    </div>
  );
};

export default Stories;
